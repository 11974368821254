import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import { Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import _fetch from "../../config/api";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import { Description, ShoppingBasket } from "@mui/icons-material";
import { Link } from "react-router-dom";
import NoResult from "./NoResult";

const Trading = (props: any) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [activationData, setActivationData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setpage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [keyCount, setKeycount] = useState(0);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getActivationDetail();
  }, [page, limit]);

  const getActivationDetail = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}report/activation-detail?limit=${limit}&page=${page}`, "GET", {})
    if (res?.status === "success") {
      setLoading(false);
      setActivationData(res?.data?.data);
      setTotalRecords(res?.total);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  return (
    <main>
      <div className="coustom_container mt-4 mb-4">

        <div className="trading-section common_card_css">
          <div className="trading-outer loader_main">
            <Row>
              <Col lg={12}>
                <div className="table_section">
                  <div className="scrolling_table">
                    <div className="scroll_under">
                      <div className="table_responsive">
                        <Table className="support_table all_tables">
                          <thead className='thead_dashboard'>
                            <tr>
                              <th> #</th>
                              <th>{t("Package")}</th>
                              <th>{t("Type")}</th>
                              <th>{t("Activation Date")}</th>

                            </tr>
                          </thead>
                          <tbody className='tbody_dashboard'>
                            {activationData?.length == 0 ? <tr className='no_data_tr'>
                              <td colSpan={6}>
                                <NoResult />
                              </td>
                            </tr> :
                              <>
                                {activationData?.map((item: any, index: any) => {
                                  return (
                                    <>

                                      <tr className="table_data_row">
                                        <td className="table_data">{page > 1 ? index + keyCount + 1 : index + 1}</td>
                                        <td className="table_data">${item?.package}</td>
                                        {/* <td className="table_data payment_fee">{item?.type}</td> */}
                                        <td className="table_data payment_fee"> {item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</td>
                                        <td className="table_data payment_fee"> {dateformat(item?.activate_date, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>

                                      </tr>
                                    </>
                                  )
                                })}
                              </>
                            }
                          </tbody>
                        </Table>
                        {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                          breakLabel="..."
                          nextLabel=">"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={1}
                          pageCount={totalRecords / 10}
                          previousLabel="<"
                        />}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Trading;
