import React, { useEffect, useState } from 'react'
import { api_url, currency, titleDescription } from '../../config/Config';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
import { Bookmark, FullscreenSharp, HistoryEdu, InfoOutlined } from '@mui/icons-material';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';


const UpdateWalletAddress = (props: any) => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    const [amount, setAmount] = useState('');
    const [bep20Address, setBEP20Address] = useState('');
    const [otp, setotp] = useState('');


    const [userDetails, setuserdetails] = useState({


        income_info: {

            available_ewallet: 0
        },

    });

    const [btnDisable, setbtnDisable] = useState(false);

    useEffect(() => {
        userDataFound();
    }, []);

    const userDataFound = async () => {

        const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
        if (data1.status === 'success') {
            localStorage.setItem("api_called", 'false');
            setBEP20Address(data1?.data?.data?.wallet_address)
            setuserdetails(data1?.data?.data);

        } else {
            // localStorage.clear();
            // navigate("/login");
        }
    }

    const SubmitFund = async () => {
        setbtnDisable(true);
        // let formData = new FormData();
        let data = {
            "bep20": bep20Address,
            // "trc20": trc20Address,
            "otp": otp
        }

        let res = await _fetch(`${api_url}auth/walletaddressupdate`, "POST", data, {});
        if (res?.status === "success") {
            userDataFound();
            toasted.success(res?.message);
            setbtnDisable(false);

        } else {
            setbtnDisable(false);
            toasted.error(res?.message);
        }
    }
   
    return (
        <main>
            <div className='coustom_container'>
                <div className="support_main">
                    <div className="support_inner mb-4">
                        <div className='card-header'>
                        </div>
                        <div className="support-section">
                            <div className='support-section_d'>

                                <p className="select_reason mb-3">
                                    <label className='form_lable' htmlFor=''> BEP20 Address</label>
                                    <input placeholder={t("BEP20 Address")} className='form-control' onChange={(e: any) => { setBEP20Address(e.target.value); }} value={bep20Address} />
                                </p>


                                <p className="select_reason mb-3">
                                    <label className='form_lable' htmlFor=''> Auth Code</label>
                                    <input placeholder={t("Auth Code")} className='form-control' onChange={(e: any) => setotp(e.target.value)} value={otp} />
                                </p>


                                {/* <div className="desc mb-3">
                                    <label className='form_lable' htmlFor=''> {t("Remark")}</label>
                                    <textarea rows={4} placeholder={t("Remark")} className='form-control' onChange={(e: any) => setRemark(e.target.value)} value={remark} />
                                </div> */}
                            </div>
                        </div>

                        <div className="send_sec">
                            <button className='new_button_css_dashboard' onClick={SubmitFund} disabled={btnDisable}>
                                {btnDisable === true &&
                                    <Loader LoaderTxt={'Please Wait...'} />
                                }
                                {btnDisable === false && <> {t("Update Wallet")}</>}
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </main>
    )
}

export default UpdateWalletAddress