import React, { useEffect, useState } from 'react'
import { api_url, currency, titleDescription } from '../../config/Config';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
import { Bookmark, FullscreenSharp, HistoryEdu, InfoOutlined } from '@mui/icons-material';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';


const WIthdraw = (props: any) => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    const [amount, setAmount] = useState('');
    const [otp, setotp] = useState('');
    const [userId, setUserId] = useState('');
    const [userDetails, setuserdetails] = useState({
        wallet_address: "",
        income_info: {

            available_income: 0
        },

    });

    const [btnDisable, setbtnDisable] = useState(false);

    useEffect(() => {
        userDataFound();
    }, []);

    const userDataFound = async () => {

        const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
        if (data1.status === 'success') {
            localStorage.setItem("api_called", 'false');

            setuserdetails(data1?.data?.data);

        } else {
            // localStorage.clear();
            // navigate("/login");
        }
    }

    const SubmitWIthdraw = async () => {
        setbtnDisable(true);
        // let formData = new FormData();
        let data = {
            "otp": otp,
            "amount": amount,
            'type': "BEP20"
        }


        let res = await _fetch(`${api_url}withdraw/withdrawal`, "POST", data, {})
        if (res?.status === "success") {
            toasted.success(res?.message);
            setbtnDisable(false);

        } else {
            setbtnDisable(false);
            toasted.error(res?.message);
        }
    }


    return (
        <main>
            <div className='coustom_container'>
                <div className="support_main">
                    <div className="support_inner mb-4">
                        <div className='card-header'>
                            
                            <p> {t("Withdraw")}  ({userDetails?.income_info?.available_income >= 0 ? `${Number(userDetails?.income_info?.available_income)?.toFixed(2)}` : "-"} {currency} )  </p>
                        </div>
                        <div className="support-section">
                            <div className='support-section_d'>

                                <p className="select_reason mb-3">
                                    <label className='form_lable' htmlFor=''> Wallet address</label>
                                    <input className='form-control' value={userDetails?.wallet_address} placeholder={t("Wallet address")} readOnly />
                                </p>


                                <p className="select_reason mb-3">
                                    <label className='form_lable' htmlFor=''> Amount</label>
                                    <input placeholder={t("Amount")} className='form-control' onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                                </p>

                                <p className="select_reason mb-3">
                                    <label className='form_lable' htmlFor=''> Auth Code</label>
                                    <input placeholder={t("Auth code")} className='form-control' onChange={(e: any) => setotp(e.target.value)} value={otp} />
                                </p>

                            </div>
                        </div>

                        <div className="send_sec">
                            {userDetails?.wallet_address ?
                                <button className='new_button_css_dashboard' onClick={SubmitWIthdraw} disabled={btnDisable}>
                                    {btnDisable === true &&
                                        <Loader LoaderTxt={'Please Wait...'} />
                                    }
                                    {btnDisable === false && <> {t("Send")}</>}
                                </button>
                                :
                                <Link to={'/profile'} className='new_button_css_dashboard' > Update Address</Link>
                            }
                        </div>


                    </div>
                </div>
            </div>
        </main>
    )
}

export default WIthdraw