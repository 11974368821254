import React, { useEffect, useState } from 'react'
import { api_url, incomesList, logo, shortLogo, title } from '../config/Config'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AccountBalanceWallet, AutoStories, Dashboard, Description, Groups, Help, Logout, MonetizationOn, Payment, PersonOutline, ShoppingBasket } from '@mui/icons-material';
import _fetch from '../config/api';
import toasted from '../config/toast';
import { useTranslation } from 'react-i18next';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useLanguage } from '../LanguageContext';

const Sidebar = () => {

  const { t, i18n } = useTranslation();
  const { selectedLanguage, changeLanguage } = useLanguage();

  let location = useLocation();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [userData, setuserData] = useState({ user_info: { email: '' } });

  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };

  const navigate = useNavigate();

  const logOut = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }
  const [sponsorId, setSponsorId] = useState('');

  useEffect(() => {

    // if (auth === "true") {

    // }
    let userDetails: any = (localStorage.getItem("user"));
    var storeData = JSON.parse(userDetails)
    // console.log('userDetails',storeData);

    setuserData(storeData);
    let storeLanguage = localStorage.getItem("language");

    if (storeLanguage) {
      data?.map((val: any) => {
        if (storeLanguage == val.value) {
          setselectLangSelect(val?.short);
          changeLanguage(val.value);
        }
      })
      // setselectLangSelect(storeLanguage)
      // console.log('storeLanguage',storeLanguage?.value);
    }
  }, [location.pathname]);


  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);




  const ToggleButtonRemover = () => {
    const body = document.body;
    body.classList.remove("show-index-bar");
  }
  const [theme, setTheme] = useState('light');

  const modechange = () => {
    if (theme === 'light') {
      setTheme('dark');
    }
    else {
      setTheme('light');
    }
    // setTheme(theme == 'light' ? 'dark' : 'light');

    localStorage.setItem('mode', theme);
  }

  // console.log("theme", theme);


  let modeTheme = localStorage.getItem("mode");

  useEffect(() => {
    const body = document.body;
    body.setAttribute("data-theme", `${modeTheme}`);

  }, [theme]);



  const data = [
    {
      value: 'en',
      text: 'English',
      short: 'En'
    },
    {
      value: 'fr',
      text: 'French',
      short: 'Fr'

    },
    {
      value: 'arabic',
      text: 'Arabic',
      short: 'Ar'

    },
    {
      value: 'chinese',
      text: 'Chinese',
      short: 'Ch'

    },
    {
      value: 'german',
      text: ' German',
      short: 'Gr'

    },
    {
      value: 'hindi',
      text: 'Hindi',
      short: 'Hi'

    },

  ];
  const [selectLangSelect, setselectLangSelect] = useState('En');

  const changeLanguageInContext = (language: any) => {
    setselectLangSelect(language?.short)
    changeLanguage(language?.value);
    localStorage.setItem('language', language?.value);
  };

  return (
    <>

      <div className="app-sidebar">
        <div className='sidbar-overly' onClick={ToggleButtonRemover}></div>


        <div className="app-sidebar3">
          <div className="sidebar">
            <div className="app-sidebar__logo">
              <Link className="header-brand" to="/dashboard">
                <div className="mode">
                  <div className='d-flex align-items-center'>
                    <img src={shortLogo} className="header-brand-img light-logo" alt="logo" />
                    <h5 className='mb-0'>{title}</h5>
                  </div>
                </div>
              </Link>
              <button className='lang_button' onClick={onOpenModal}>{selectLangSelect}<KeyboardArrowDownIcon /></button>

              <Modal
                open={open}
                onClose={onCloseModal}
                classNames={{
                  modal: "lang_modal",
                }}
                center>
                <div className="heading">
                  <h1>Select Language:</h1>
                </div>
                <div className="bottom_links">

                  {data?.map((val) => {
                    return (
                      <button className="sign-modal__list--item" onClick={() => { changeLanguageInContext(val); onCloseModal() }}>{val?.text}<span><DoubleArrowIcon /></span></button>

                    )
                  })}
                </div>
              </Modal>

            </div>
            <ul className="sidebar_menu">

              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className='only_button'>
                    <Link to="/dashboard" onClick={ToggleButtonRemover} className={location.pathname === '/dashboard' ? "drop_link active_Link" : ' drop_ink'}><Dashboard />{t("Dashboard")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="1">
                  <Accordion.Header className='only_button'>
                    <Link to="/profile" onClick={ToggleButtonRemover} className={location.pathname === '/profile' ? "drop_link active_Link" : ' drop_ink'}><PersonOutline />{t("Profile")}</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                <Accordion.Item eventKey="1">
                  <Accordion.Header><span><AccountBalanceWallet />{t("Profile")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>

                      <li><Link onClick={ToggleButtonRemover} to={'/profile'}>{t('Edit Profile')}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to={'/update-wallet'}>{t('Update Wallet Address')}</Link></li>

                      <li><Link onClick={ToggleButtonRemover} to={'/walletHistory'}>{t('Wallet Activities')}</Link></li>



                    </ul>
                  </Accordion.Body>
                </Accordion.Item>



                <Accordion.Item eventKey="112">
                  <Accordion.Header className='only_button'>
                    <Link to="/main-overview" onClick={ToggleButtonRemover} className={location.pathname === '/main-overview' ? "drop_link active_Link" : ' drop_ink'}><AccountBalanceWallet />{t("Overview")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="2">
                  <Accordion.Header className='only_button'>
                    <Link to="/deposit" onClick={ToggleButtonRemover} className={location.pathname === '/deposit' ? "drop_link active_Link" : ' drop_ink'}><MonetizationOn /> {t('Deposit')}</Link>
                  </Accordion.Header>
                </Accordion.Item> */}


                <Accordion.Item eventKey="2">
                  <Accordion.Header><span><AccountBalanceWallet />{t("Deposit")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>

                      <li><Link onClick={ToggleButtonRemover} to={'/deposit'}>{t('Deposit')}</Link></li>

                      <li><Link onClick={ToggleButtonRemover} to={'/depositHistory'}>{t('Wallet TXN History')}</Link></li>



                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header><span><AccountBalanceWallet />{t("Fund Management")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>

                      <li><Link onClick={ToggleButtonRemover} to={'/fund'}>{t('Fund Transfer')}</Link></li>

                      <li><Link onClick={ToggleButtonRemover} to={'/transfer-fund'}>{t('Transfer Fund History')}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to={'/wallet-ledger'}>{t('All Fund History')}</Link></li>



                    </ul>
                  </Accordion.Body>
                </Accordion.Item>


                {/* <Accordion.Item eventKey="3">
                  <Accordion.Header><span><ShoppingBasket />{t("Activation")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/buy-infra'> {t("Activate Account")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to="/trading-profit">{t("Activation History")}</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}


                <Accordion.Item eventKey="4">
                  <Accordion.Header className='only_button'>
                    <Link to="/team" onClick={ToggleButtonRemover} className={location.pathname === '/team' ? "drop_link active_Link" : ' drop_ink'}><Groups />{t("Team")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="5">
                  <Accordion.Header className='only_button'>
                    <Link to="/fund" onClick={ToggleButtonRemover} className={location.pathname === '/fund' ? "drop_link active_Link" : ' drop_ink'}><MonetizationOn /> {t('Fund Transfer')}</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                {/* <Accordion.Item eventKey="5">
                  <Accordion.Header className='only_button'>
                    <Link to="/overview" className={location.pathname === '/overview' ? "drop_link active_Link" : ' drop_ink'}><ViewCompact />{t("Overview")}</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                <Accordion.Item eventKey="6">
                  <Accordion.Header><span><AccountBalanceWallet />{t("Income")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {incomesList?.map((income: any) => {
                        return (
                          <li><Link onClick={ToggleButtonRemover} to={'/report/' + income?.type}>{t(income?.name)}</Link></li>

                        );
                      })}


                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header className='only_button'>
                    <Link onClick={ToggleButtonRemover} to="/ledger" className={location.pathname === '/ledger' ? "drop_link active_Link" : ' drop_ink'}><Description />{t("Ledger")}</Link>
                  </Accordion.Header>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header className='only_button'>
                    <Link to="/pins" onClick={ToggleButtonRemover} className={location.pathname === '/pins' ? "drop_link active_Link" : ' drop_ink'}><MonetizationOn /> {t('Pins')}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="8">
                  <Accordion.Header><span><CardGiftcard />Promotions</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><a>IGNITE</a></li>
                      <li><a>Golden Diwali</a></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}

                {/* <Accordion.Item eventKey="9">
                  <Accordion.Header className='only_button'>
                    <Link onClick={ToggleButtonRemover} to="/" className={location.pathname === '/Cryptic' ? "drop_link active_Link" : ' drop_ink'}><Key />Cryptic ID</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                <Accordion.Item eventKey="10">
                  <Accordion.Header><span><Payment />{t("Payout")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/Withdraw'>{t("Request")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/pay-history'> {t("History")}</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                  <Accordion.Header className='only_button'>
                    <Link onClick={ToggleButtonRemover} to="/presentations" className={location.pathname === '/presentations' ? "drop_link active_Link" : ' drop_ink'}><AutoStories />{t("Presentations")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="12">
                  <Accordion.Header className='only_button'>
                    <Link to="/support" className={location.pathname === '/support' ? "drop_link active_Link" : ' drop_ink'}><Help />Support</Link>
                  </Accordion.Header>
                </Accordion.Item> */}
                <Accordion.Item eventKey="12">
                  <Accordion.Header><span><Help /> {t("Support")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>

                      <li><Link onClick={ToggleButtonRemover} to='/create-ticket'> {t("Create Ticket")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/ticket-history'>{t("Ticket History")}</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="14">
                  <Accordion.Header className='only_button'>
                    <Link to="/" className={location.pathname === '/logout' ? "drop_link active_Link" : ' drop_ink'} onClick={logOut}><Logout />{t("Log out")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

              </Accordion>


            </ul>
          </div>


        </div>
      </div>

    </>
  )
}

export default Sidebar