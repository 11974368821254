import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { api_url, logo, title, titleDescription } from "../../config/Config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import { getData } from "country-list";
import Loader from "../../config/Loader";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import Select, { SingleValue } from 'react-select';
import { ArrowDropDownRounded } from "@mui/icons-material";
import __img_login from "../../Assets/homepage/loginsec.svg";




const Register = (props: any) => {
  const [btnDisable, setbtnDisable] = useState(false);

  const { sponser,getPin } = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [refferalID, setRefferalID] = useState("");
  // const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setphone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pin, setPin] = useState("");

  const [showPasswords, setShowPasswords] = useState(false);
  const [selectCheckbox, setSelectCheckbox] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(
    null
  );

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswords = () => {
    setShowPasswords(!showPasswords);
  };

  useEffect(() => {
    if (sponser != null) {
      setRefferalID(sponser);
    }
    if(getPin != null){
      setPin(getPin);
    }
  }, []);

  type CountryOption = {
    value: string;
    label: string;
    flagUrl: string;
  };

  const CustomOption: React.FC<{
    innerProps: any;
    label: string;
    data: CountryOption;
  }> = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img src={data.flagUrl} alt={label} style={{ marginRight: "8px" }} />
      {label}
    </div>
  );

  const countryOptions: CountryOption[] = getData().map((country) => ({
    value: country.code,
    label: country.name,
    flagUrl: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`,
  }));

  const submitRegister = async (e: any) => {
    setbtnDisable(true);
    e.preventDefault();

    let data = {
      sponsor_id: refferalID,
      name: firstName,
      // lastName: lastName,
      phone: phone,
      email: email,
      country: selectedCountry?.label,
      country_flag: selectedCountry?.flagUrl,
      pin: pin,
      password: password,
      confirm_password: confirmPassword,
    };

    let res = await _fetch(`${api_url}auth/signup`, "POST", data, {});
    if (selectCheckbox) {
      if (res?.status === "success") {
        localStorage.setItem("auth", 'true');
        localStorage.setItem("accessToken", res?.token);
        localStorage.setItem("user", JSON.stringify(res?.data?.user));
        toasted.success(res?.message);
        Navigate("/dashboard");

        // Navigate("/waiting-conformation", {
        //   state: firstName + " " + lastName,
        // });

        setbtnDisable(false);
      } else {
        toasted.error(res?.message);
        setbtnDisable(false);
      }
    } else {
      setbtnDisable(false);

      toasted.error("Please accept terms and conditions!");
    }
  };

  return (
    <>

      <div className="__auths">
        <div className="__authcard">
          <div className="row">

            <div className="col-lg-5 IMg__src_out">
              <div className="IMg__src">
                <div>
                  <div className="__img">
                    <img src={__img_login} alt="img" />

                  </div>
                  <h5> Create an account </h5>
                  <p> Enter your personal details to create account</p>
                </div>

                <h6>Already have an account? <br /> <Link to="/login">Sign in now</Link></h6>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="__right_">
                <div className="card-header">
                  <Link to="/"> <img src={logo} alt={title + "logo"} /></Link>

                  <button type="button" className="btn"> ENG <ArrowDropDownRounded /> </button>
                </div>

                <div className="card-body">
                  <h5>Sign in to Your Account</h5>
                  <p> Please sign in to enter in {title}</p>

                  <div className="Input_area">

                    <Form className="form-start">

                      <div className="form-start-flex">
                        <input type="text" id="pin" placeholder="Scratch code" onChange={(e) => setPin(e.target.value)} value={pin} readOnly/>
                      </div>

                      <div className="form-start-flex">
                        <input type="text" id="email" placeholder="Sponsor id" onChange={(e) => setRefferalID(e.target.value)} value={refferalID} />

                        <input type="text" id="fname" placeholder="Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                      </div>

                      <div className="form-start-flex">
                        <PhoneInput
                          inputStyle={{ height: '45px', padding: '0px 20px 0px 50px', outline: 'none', color: '#000', width: '100%', fontSize: '14px' }}
                          containerStyle={{}}
                          searchClass="search-class"
                          disableSearchIcon={false}
                          enableTerritories
                          countryCodeEditable={true}
                          placeholder='Phone Number'
                          buttonStyle={{ width: '47px', height: "45px" }}
                          dropdownStyle={{ height: '300px' }}
                          country={"in"}
                          value={phone}
                          onChange={(e: any) => setphone(e)}

                          enableSearch={true} />

                        <input type="text" id="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                      </div>

                      <Select
                        options={countryOptions}
                        value={selectedCountry}
                        onChange={(option: SingleValue<CountryOption>) =>
                          setSelectedCountry(option)
                        }
                        components={{ Option: CustomOption }}
                        isSearchable
                        placeholder="Select a country..."
                      />

                      <div className="form-start-flex">
                        <div className="hide-pass">
                          <input className="input_pass" type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} id="password" placeholder="Password" />

                          <button type="button" className="btn__sc" onClick={handleTogglePassword}>
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </button>
                        </div>

                        <div className="hide-pass">
                          <input className="input_pass" type={showPasswords ? "text" : "password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} id="cpassword" placeholder="Confirm Password" />

                          <button type="button" className="btn__sc" onClick={handleTogglePasswords}>
                            {showPasswords ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="login-remfor">
                        <div className="login-check">
                          <input type="checkbox" id="rem-form" onChange={(e: any) => setSelectCheckbox(e.target.checked)} />
                          <label className="me-label ms-1" htmlFor="rem-form"> I agree to the terms of service </label>
                        </div>
                      </div>




                      <div className="btn__s">
                        <button type="submit" className="log-btn" onClick={submitRegister} disabled={btnDisable} >
                          {btnDisable === true && (
                            <Loader />
                          )}
                          {btnDisable === false && <span className="btnText">sign up</span>}
                        </button>
                      </div>

                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default Register;
