
import site_logo from '../Assets/Icon/Logo.png';
import site_short_logo from '../Assets/Icon/shortLogo.png';
export const logo = site_logo;
export const shortLogo = site_short_logo;
export const title = 'Four By Four';
export const websiteName = 'Four By Four';
export const shortTitle = 'Four By Four';
export const titleDescription = 'Four By Four';
export const Image_path = site_logo;
export const currency = "USDT";
export const SupportEmail = "support@fourbyfour.live";


export const bseScanLink = 'https://bscscan.com/tx/';
export const website = 'https://fourbyfour.live/';
export const api_url = 'https://ap.fourbyfour.live/api/v1/';

// export const api_url = 'http://192.168.18.39:8003/api/v1/';
// INX5244742

export const incomesList = [
    { 'type': 'level_income', 'name': 'Level Bonus' },
    { 'type': 'loyalty_club_pool1', 'name': 'Loyalty Club Pool 1' },
    { 'type': 'loyalty_club_pool2', 'name': 'Loyalty Club Pool 2' },
    { 'type': 'loyalty_club_pool3', 'name': 'Loyalty Club Pool 3' },

];

