import React from 'react';
import { ClipLoader } from 'react-spinners'


const Loader = (props:any) => {
    return (
        <div className="color-ring">
            <ClipLoader className='loader-section' color="#000" />

            {props?.LoaderTxt}
        </div>
    );
}

export default Loader;
