import React, { useEffect } from 'react';
import waitingLoader_gif from '../../Assets/Image/waitingLoader.gif'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import toasted from '../../config/toast';
import _fetch from '../../config/api';
import { api_url } from '../../config/Config';


const AdminUserLogin = (props: any) => {
  // const { width, height } = useWindowSize()


  const location = useLocation()
  const details = location.state
  const { email, pass } = useParams();
  const Navigate = useNavigate();

  const submitLogin = async () => {
    let data = {
      "email": email,
      "password": pass,
      // "otp": otp
    }
    let res = await _fetch(`${api_url}auth/login`, "POST", data, {})
    // if(selectCheckbox) {

    if (res?.status === 'success') {
      // if (res?.data?.user?.verified == true) {
      localStorage.setItem("auth", 'true');
      localStorage.setItem("accessToken", res?.token);
      localStorage.setItem("user", JSON.stringify(res?.data?.user));
      toasted.success(res?.message);
      Navigate("/dashboard");

    }
    else {
      localStorage.clear();
      toasted.error(res?.message);
      // }
    }

  }

  useEffect(() => {
    submitLogin()

  }, [])

  return (
    <div className='__waitingScreen'>
      <div className='__card'>
        <img src={waitingLoader_gif} alt='loader' />
      </div>

    </div>
  );
}

export default AdminUserLogin;
