import React, { useEffect, useState } from 'react'
import { api_url, currency, titleDescription } from '../../config/Config';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
import { Bookmark, FullscreenSharp, HistoryEdu, InfoOutlined } from '@mui/icons-material';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';


const Fund = (props: any) => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    const [amount, setAmount] = useState('');
    const [remark, setRemark] = useState('');
    const [userId, setUserId] = useState('');
    const [userDetails, setuserdetails] = useState({


        income_info: {

            available_ewallet: 0
        },

    });

    const [btnDisable, setbtnDisable] = useState(false);

    useEffect(() => {
        userDataFound();
    }, []);

    const userDataFound = async () => {

        const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
        if (data1.status === 'success') {
            localStorage.setItem("api_called", 'false');

            setuserdetails(data1?.data?.data);

        } else {
            // localStorage.clear();
            // navigate("/login");
        }
    }

    const SubmitFund = async () => {
        setbtnDisable(true);
        // let formData = new FormData();
        let data = {
            "userid": userId,
            "amount": amount,
            // "remark": remark,
        }

        let res = await _fetch(`${api_url}fund/fund-transfer`, "POST", data, {})
        if (res?.status === "success") {
            toasted.success(res?.message);
            setbtnDisable(false);

        } else {
            setbtnDisable(false);
            toasted.error(res?.message);
        }
    }
    const [userName, setuserName] = useState('');
    const CheckUser = async (user: any) => {
        let res = await _fetch(`${api_url}auth/checkSponsor`, "POST", { 'sponsor_id': user }, {})
        if (res?.status === 'success') {
            setuserName(res?.data?.name)
        }else{
            setuserName('Invalid User!');

        }
    }

    return (
        <main>
            <div className='coustom_container'>
                <div className="support_main">
                    <div className="support_inner mb-4">
                        <div className='card-header'>
                            <p> {t("Fund")} Transfer ({userDetails?.income_info?.available_ewallet >= 0 ? `${Number(userDetails?.income_info?.available_ewallet)?.toFixed(2)}` : "-"} {currency} )  </p>
                        </div>
                        <div className="support-section">
                            <div className='support-section_d'>

                                <p className="select_reason mb-3">
                                    <label className='form_lable' htmlFor=''> User ID</label>
                                    <input placeholder={t("User ID")} className='form-control' onBlur={(e: any) => { setUserId(e.target.value); CheckUser(e.target.value) }} />
                                    <span className='text-danger'>{userName}</span>
                                </p>


                                <p className="select_reason mb-3">
                                    <label className='form_lable' htmlFor=''> Amount</label>
                                    <input placeholder={t("Amount")} className='form-control' onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                                </p>


                                {/* <div className="desc mb-3">
                                    <label className='form_lable' htmlFor=''> {t("Remark")}</label>
                                    <textarea rows={4} placeholder={t("Remark")} className='form-control' onChange={(e: any) => setRemark(e.target.value)} value={remark} />
                                </div> */}
                            </div>
                        </div>

                        <div className="send_sec">
                            <button className='new_button_css_dashboard' onClick={SubmitFund} disabled={btnDisable}>
                                {btnDisable === true &&
                                    <Loader LoaderTxt={'Please Wait...'} />
                                }
                                {btnDisable === false && <> {t("Send")}</>}
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </main>
    )
}

export default Fund