import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>fourbyfour</h1>
      <p style={styles.subtext}>Stay tuned! Our new website is coming soon.</p>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    textAlign: 'center',
    marginTop: '100px',
  },
  heading: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#333',
  },
  subtext: {
    fontSize: '18px',
    color: '#666',
  },
};

export default ComingSoon;
