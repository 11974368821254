import React, { useEffect, useState } from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { api_url, titleDescription } from "../../config/Config";
import _fetch from "../../config/api";
import QRCode from "qrcode.react";
import toasted from "../../config/toast";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import { ArrowLeft, BookmarkAdded, CopyAll, DoneAll, } from "@mui/icons-material";
import NoResult from "./NoResult";




const Deposit = (props: any) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [wallets, setWallets] = useState({ bep20: "", trc20: "" });
  const [BEP20, setBEP20] = useState('');
  const [barcodeValue, setBarCodeValue] = useState(wallets?.bep20);
  const [walletHeding, setWalletHeding] = useState(" USDT - BEP20 Deposit");
  const [barCodeHeding, setBarCodeheading] = useState(' USDT - BEP20');
  const [trxType, setTrxType] = useState('bep20');
  const [countHit, setCountHit] = useState(0);
  const [fundValues, setFundValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [coinPrice, setcoinPrice] = useState(0);


  useEffect(() => {
    getWallets();
    getPrice()
  }, []);

  const getWallets = async () => {
    let data = await _fetch(`${api_url}auth/getWallets`, "GET", {})

    if (data?.status === "success") {
      setWallets(data);
    }
  }

  const getPrice = async () => {
    let data = await _fetch(`${api_url}activation/tokenPrice`, "GET", {})

    if (data?.status === "success") {
      setcoinPrice(data?.token_price);
    }
  }


  useEffect(() => {
    getfundData();
  }, [page, limit]);

  const getfundData = async () => {
    setLoading(true);
    let data = await _fetch(`${api_url}report/depositFunds?limit=${limit}&page=${page}`, "GET", {})

    if (data?.status === "success") {
      setLoading(false);
      console.log('data?.data?.data', data?.data?.data);

      setFundValues(data?.data?.data);
      setTotalRecords(data?.total);

    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  useEffect(() => {
    if (countHit < 50) {
      const timer = setInterval(() => {
        checkTransaction();
        var calHit = parseFloat(`${countHit + 1}`);
        setCountHit(calHit);
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [countHit]);

  const checkTransaction = async () => {
    // setLoading(true);
    const encodedBlockchain = encodeURIComponent(trxType);

    let data = await _fetch(`${api_url}fund/checktxn?blockchain=${encodedBlockchain}`, "GET", {})

    if (data?.status === "success") {
      // setLoading(false);
      toasted.success(data?.message);
      getfundData();
    }
  }

  function bep20wallet() {
    setBEP20(wallets?.bep20);
    setBarCodeValue(wallets?.bep20);
    setWalletHeding("USDT - BEP20 Deposit");
    setBarCodeheading("USDT - BEP20");
    setTrxType('bep20');
  }

  function trc20wallet() {
    setBEP20(wallets?.trc20);
    setBarCodeValue(wallets?.trc20);
    setWalletHeding("USDT - TRC20 Deposit");
    setBarCodeheading("USDT - TRC20");
    setTrxType('trc20');

  }

  const copyToClipboard = (address: any) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success('Address copied successfull!');
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  const [tabmode, settabmode] = useState(true);


  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(true);

    const timer = setTimeout(() => {
      setShowContent(false)
    }, 1000);

    return () => clearTimeout(timer);
  };


  const reloadPage = () => {
    window.location.reload();
  };


  return (


    <main>
      <div className="coustom_container mt-4 mb-4">

        <div className="deposit-section">
          <div className="row Main__row">

            {/* <div className="col-12">
              <div className="card_heading__df common_card_css">
                <p className=""> KSN Price <span>${coinPrice}</span></p>
                <Link className="" to="https://pancakeswap.finance/info/token/0xC8A11F433512C16ED895245F34BCC2ca44eb06bd" target="_blank" > 
                <img src={pancakeswapIcon} alt="icon"/>
                <span>Pancakeswap Buy KSN</span>
                </Link>
              </div>
            </div> */}

            <div className="col-12">
              <div className="card_heading__df common_card_css">
                <p className=""> {tabmode ? <> {walletHeding} </> : <> Deposit History</>}</p>

                <button type="button" className="btn" onClick={() => { settabmode(!tabmode) }}>
                  {tabmode ? <> Deposit History </> : <> Deposit</>}
                </button>
              </div>
            </div>

            {
              tabmode ?
                <>
                  <div className="col-12">
                    <div className="card deposit__card">
                      <div className="__left__qr">
                        <QRCode className="qr-code" value={barcodeValue ? barcodeValue : wallets?.bep20} />

                        <div className="deposit-links">
                          <p>Top up <br /> via QR-code</p>

                          <p> {t(`Send only ${barCodeHeding} to your address`)}</p>
                        </div>

                      </div>

                      <div className="card-body p-0">
                        <div className="default-block__title --border-bottom for_driction ">

                          <div className="default-block__title--level --bronze flex-column">
                            <h4 className="text-black mb-0">  {walletHeding} </h4>
                            {/* {t(`Send only ${barCodeHeding} to your address`)} */}
                          </div>
                        </div>

                        <p className="text-danger mt-2" style={{ fontSize: "14px" }}> <BookmarkAdded /> Attention! {t("Sending wrong tokens or to the wrong network, will result in a loss of fund")} </p>


                        <div className="scanner-pic">
                          <div className="scanner-pic__datss">
                            <div className="w-100">
                              {/* <div className="deposit-links">
                                <p className="text-dark mb-1"> <ArrowLeft /> 1 USDT/{(1 / coinPrice).toFixed(4)} KSN</p>
                                <p className="text-dark mb-3"><ArrowLeft /> 25 USDT/{(25 / coinPrice).toFixed(4)} KSN</p>
                              </div> */}
                              <p className="qr__address_as">{barCodeHeding}</p>

                              <div className="link-copy">
                                <input className="text-dark" type='text' value={BEP20 ? BEP20 : wallets?.bep20} disabled></input>

                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      <p className='txt-nowrap mb-0'>  {showContent ? 'Copied!' : 'Copy address'}</p>
                                    </Tooltip>
                                  }>
                                  <button type='button' className='btn text-dark copybtn' onClick={() => { copyToClipboard(((walletHeding === "USDT - BEP20 Deposit") ? wallets?.bep20 : wallets?.bep20)); toggleContent() }}>
                                    {showContent ? <DoneAll /> : <CopyAll />}
                                  </button>

                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="deposit-links mt-3">

                          <Link to="#" className="new_button_css_dashboard" onClick={bep20wallet}>USDT - BEP20</Link>
                        </div>
                      </div>
                    </div>
                  </div>

                </>
                :
                <>
                  <div className="col-12">
                    <div className="card deposit_table__card">
                      <div className="scrolling_table">
                        <div className="scroll_under">
                          <div className="table_responsive">
                            <Table className="support_table all_tables">
                              <thead className='thead_dashboard'>
                                <tr>
                                  <th>{t("#")}</th>
                                  {/* {type == 'level_income' || type == 'direct_income' && <th>{t("From")}</th>} */}
                                  <th>{t("Amount")}</th>
                                  <th>{t("Remark")}</th>

                                  <th>{t("Hash")}</th>
                                  <th>{t("Created Date")}</th>
                                </tr>
                              </thead>
                              <tbody className='tbody_dashboard'>
                                {fundValues.length == 0 ? <tr className='no_data_tr'>
                                  <td colSpan={4}>
                                    <NoResult />
                                  </td>
                                </tr> :
                                  <>
                                    {fundValues?.map((item: any, index: any) => {
                                      return (
                                        <>
                                          <tr className="table_data_row">
                                            <td className="table_data"><div className="pic_text">{page > 1 ? index + keyCount + 1 : index + 1}</div></td>
                                            <td className="table_data payment_fee">${item?.amount?.toFixed(2)}</td>
                                            <td className="table_data payment_fee">${item?.remark}</td>

                                            <td className="table_data">
                                              <a href={item?.blockscan_url} target="_blank" className="btn new_button_css_dashboard">View</a>
                                            </td>
                                            <td className="table_data">{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                          </tr>
                                        </>
                                      )
                                    })}
                                  </>
                                }

                              </tbody>
                            </Table>
                            {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                              breakLabel="..."
                              nextLabel=">"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={1}
                              pageCount={totalRecords / 10}
                              previousLabel="<"
                            />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
            }

          </div>
        </div>
      </div>
    </main>
  );
};

export default Deposit;
