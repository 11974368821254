import React, { useEffect, useState } from "react";
import { api_url, currency, shortLogo, titleDescription } from "../../config/Config";
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import { Link, useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';
import Loader from "../../config/Loader";
import { AccountBalanceWallet, HistoryEdu, Star } from "@mui/icons-material";

const BuyInfra = (props: any) => {
    const { t, i18n } = useTranslation();


    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });
    const [open, setOpen] = useState(false);

    // const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [professoinalPackage, setProfessoinalPackage] = useState({ title: "", package_range: "", _id: "" });
    const [advancedPackage, setAdvancedPackage] = useState({ title: "", package_range: "", _id: "" });
    const [premiumPackage, setPremiumPackage] = useState({ title: "", package_range: "", _id: "" });
    const [valueChange, setValueChange] = useState(25);
    const [method, setMethod] = useState(1);
    const [userName, setUserName] = useState('');
    const [avaliableFund, setAvaliableFund] = useState({ fundValue: "", reBuyValue: "" });
    const [loading, setLoading] = useState(true);
    const [btnDisable, setbtnDisable] = useState(false);


    useEffect(() => {
        getPackage();
        getAvaliableFund();
    }, []);

    const Navigate = useNavigate();
    const [packageAll,setpackageAll] = useState([]);
    const getPackage = async () => {
        let res = await _fetch(`${api_url}activation/packages`, "GET", {})
        if (res?.status === 'success') {
            setpackageAll(res?.Package);
        }
    }

    const getAvaliableFund = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}profile/avaliablefund`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setAvaliableFund(res?.data?.AvaliableDeposit);
        }
    }

    

    const BuyNow = async () => {
        setLoading(true);
        setbtnDisable(true)
        let data = {
            "username": userName,
            "package": valueChange,
           
        }
        let res = await _fetch(`${api_url}activation/activateNow`, "POST", data)
        if (res?.status === 'success') {
            toasted.success(res?.message);
            setLoading(false);
            Navigate("/dashboard");
            setbtnDisable(false)
        }
        else {
            toasted.error(res?.message);
            setLoading(false);
            setbtnDisable(false)
        }
    }

    const [findedUserName, SetfindedUserName] = useState();
    const checkUser = async (user_id: any) => {
        let res = await _fetch(`${api_url}auth/checkUser?user=${user_id}`, "GET", {})
        if (res?.status === "success") {
            SetfindedUserName(res?.data?.name);
        }
    }

    return (
        <>
            <main>
                <div className="coustom_container mt-4 mb-4">

                    <div className="buy_section loader_main">
                        <div className="card_heading__df common_card_css">
                            <p className=""> <AccountBalanceWallet /> {t("Topup Fund Balance")} : ${Number(avaliableFund?.fundValue)?.toFixed(2)}</p>
                        </div>

                        <div className="row mt-4">

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card christmas_card common_card_css mb-4">

                                    <div className="card-body">
                                        <div className="card_bottom_Sec">
                                            <div className="card__header">
                                                <img src={shortLogo} alt="logo"/>
                                            </div>

                                            <p className="select_amount">
                                            <label htmlFor="" className="text-info">Username</label>
                                                <input className="form-control" type="text" placeholder={t("enter username")} onBlur={(e: any) => { setUserName(e.target.value); checkUser(e.target.value) }} />
                                                <span className="text-success">{findedUserName}</span>
                                            </p>
                                            <p className="select_amount">
                                                <label htmlFor="" className="text-info">Package</label>
                                                <select className="form-control" aria-label="Default select example" onChange={(e: any) => setValueChange(e.target.value)}>
                                                    {packageAll?.map((val:any) => {
                                                        return(
                                                            <option value={val?.package}>{t( val?.package +' ' + currency)}</option>

                                                        )
                                                    })}
                                                </select>
                                            </p>
                                            <div><button type="button" className="btn btn-success w-100" onClick={() => {BuyNow()}}>Submit</button></div>
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>


                           
                        </div>
                    </div>
                </div>
            </main>


            <Modal open={open} onClose={onCloseModal} center classNames={{
                modal: "buy_modal",

            }}>
                <p className="modal_data mb-3">
                    <h6>Price</h6>
                    <h6>$6,000</h6>
                </p>
                <p className="modal_data mb-3">
                    <h6>Available</h6>
                    <h6>$68,000</h6>
                </p>
                <p className="modal_data mb-3">
                    <h6>Rebuy</h6>
                    <h6>$22,000</h6>
                </p>
                <p className="need">Need 3,888.50 in wallet to make a purchase.</p>
                <div className="check_input">
                    <input type="checkbox" placeholder="" /><label>Use Rebuy Amount</label>

                </div>
                <p className="mt-3 mb-4">(20% of Infra Price can be used from Rebuy)</p>
                <a href="/deposit" className="button_style load_btn d-flex justify-content-center">Load eUSD</a>


            </Modal>
        </>

    );
};

export default BuyInfra;
