import React from 'react'
import { logo, title } from '../config/Config'
import { Link } from 'react-router-dom'
import { Facebook, Instagram, LocationOn, NorthEastRounded, SupportAgent, Telegram } from '@mui/icons-material'
import { Col, Row } from 'react-bootstrap'
import XIcon from '@mui/icons-material/X';


const HomeFooter = () => {
    return (
        <div className='four-footer'>
            <div className='top_footer'>
                <div className='four-index-container'>
                    <Row>
                        <Col lg={5}>
                            <div className='__logo_sec'>
                               <Link to="#" className='__link'>
                                    <img src={logo} alt={title + "logo"} />
                                    <h4> {title}</h4>
                               </Link>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className='__txt_sec'>
                                <p> <LocationOn /> </p>
                                <div className='txt__s'>
                                    <p>Location</p>
                                    <h5>9870 St Vincent Place, DC 45 Fr 45</h5>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='__txt_sec'>
                                <p> <SupportAgent /> </p>
                                <div className='txt__s'>
                                    <p>Call Us</p>
                                    <h5>+(310)123-4567</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='bottom_footer'>
                <div className='four-index-container'>
                    <Row>
                        <Col lg={6}>
                            <div className='__sec __about'>
                                <h5 className='__secheading'> About  <span></span></h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='__sec'>
                                <h5 className='__secheading'> Social Media  <span></span></h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link to="https://www.facebook.com/" className="nav-link facebook" target="_blank"> <Facebook /> Facebook </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="https://twitter.com/" className="nav-link XIcon" target="_blank"><XIcon /> twitter </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="https://www.instagram.com/" className="nav-link Instagram" target="_blank"><Instagram /> Instagram </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="https://web.telegram.org/" className="nav-link Telegram" target="_blank"><Telegram /> Telegram </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='__sec bottom_sec'>
                                <h5 className='__secheading'> Quick Links  <span></span></h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link" target="_blank"> <NorthEastRounded /> Home </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="#" className="nav-link" target="_blank"><NorthEastRounded /> About </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="#" className="nav-link" target="_blank"><NorthEastRounded /> Service </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="#" className="nav-link" target="_blank"><NorthEastRounded /> Contact Us </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='bottom_ca'>
                <div className='four-index-container'>
                    <p>Copyrights © {(new Date().getFullYear())} All rights reserved {title}.</p>
                </div>
            </div>
        </div>
    )
}

export default HomeFooter
