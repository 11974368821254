import React, { useEffect, useState } from "react";
import { shortLogo, title } from "../config/Config";
import { Button, Dropdown, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Email, Facebook, Instagram, LocalPhone, Menu, Telegram } from "@mui/icons-material";
import XIcon from '@mui/icons-material/X';

const Header = () => {

    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [show, setShow] = useState(false);




    const HeaderData = [
        {
            LinkName: "About",
            LinkUrl: '#about',
            dropdown: null
        },
        {
            LinkName: "Services",
            LinkUrl: '#services',
            dropdown: null,
        },
        {
            LinkName: "Contact Us",
            LinkUrl: '#Contact',
            dropdown: null
        },
    ];



    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 150 && !isHeaderFixed) {
                setIsHeaderFixed(true);
            } else if (scrollPosition <= 150 && isHeaderFixed) {
                setIsHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isHeaderFixed]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const PhoneNumber = "+97 1234567891";
    const EmailAddress = "info@fourbyfour.live";

    var auth = localStorage.getItem("auth");


    return (
        <>

            <div className="fourTop_bar">
                <div className="four-container">
                    <div className="__left">
                        <ul className="nav">
                            <li className="nav-item">
                                <Link to={"tel:" + PhoneNumber} className="nav-link" target="_blank"> <LocalPhone /> {PhoneNumber}  </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"mailto:" + EmailAddress} target="_blank" className="nav-link"><Email /> {EmailAddress} </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="__right">

                        <ul className="nav">
                            <li className="nav-item">
                                <Link to="https://www.facebook.com/" className="nav-link facebook" target="_blank"> <Facebook /> </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="https://twitter.com/" className="nav-link XIcon" target="_blank"><XIcon /> </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="https://www.instagram.com/" className="nav-link Instagram" target="_blank"><Instagram /> </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="https://web.telegram.org/" className="nav-link Telegram" target="_blank"><Telegram /> </Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className={isHeaderFixed ? 'four-header fixed_' : 'four-header'}>
                <div className="four-container">
                    <div className="four-header-area">
                        <div className="four-logo">
                            <Link to="/">
                                <img src={shortLogo} alt={title + 'Logo'} />
                                <span>{title}</span>
                            </Link>
                        </div>

                        <Button onClick={handleShow} className="toggle_btn"> <Menu /> </Button>


                        <ul className="lg__size">
                            <li className="nav-item">
                                <Link to="#" className="nav-link">Home </Link>
                            </li>
                            {HeaderData.map((item: any, index: any) => (

                                <li className="nav-item" key={index}>
                                    {item.dropdown == null && <a href={item.LinkUrl} className="nav-link">{item.LinkName} </a>}

                                    {item.dropdown && (
                                        <ul className="p-0">
                                            <Dropdown>
                                                <Dropdown.Toggle className="bg-transparent border-0 text-black nav-link" id="dropdown-basic">
                                                    {item.LinkName}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {item.dropdown.map((subItem: any) => (
                                                        <Link key={subItem.dropLinkName} className="dropdown-item" to={subItem.dropLinkUrl}>{subItem.dropLinkName} </Link>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>

                        <ul className="lg__size">
                            <li className="nav-item btn_item">
                                {auth ?
                                    <Link className="nav-button" to="/dashboard"> Dashboard</Link>
                                    :
                                    <>
                                        <Link className="nav-button" to="/login"> Login</Link>
                                        <Link className="nav-button" to="/register"> Sign Up</Link>
                                    </>
                                }
                            </li>
                        </ul>


                        {/* this phone view dropdown */}
                        <Offcanvas placement={'end'} show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <img src={shortLogo} alt={title + 'Logo'} width="60px" />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <ul className="phone-View">
                                    <li className="nav-item">
                                        <Link to="/" onClick={handleClose} className="nav-link">Home </Link>
                                    </li>
                                    {HeaderData.map((item: any, index: any) => (

                                        <li className="nav-item" key={index}>
                                            {item.dropdown == null && <a href={item.LinkUrl} onClick={handleClose} className="nav-link">{item.LinkName} </a>}

                                            {item.dropdown && (
                                                <ul className="p-0">
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="bg-transparent border-0 text-black nav-link" id="dropdown-basic">
                                                            {item.LinkName}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {item.dropdown.map((subItem: any) => (
                                                                <Link key={subItem.dropLinkName} className="dropdown-item" to={subItem.dropLinkUrl}>{subItem.dropLinkName} </Link>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </ul>
                                            )}
                                        </li>
                                    ))}

                                    <li className="nav-item nav-item_btn">

                                        {auth ?
                                            <Link className="nav-button" to="/dashboard"> Login</Link>
                                            :
                                            <>
                                                <Link className="nav-button" to="/login"> Login</Link>
                                                <Link className="nav-button" to="/register"> Sign Up</Link>
                                            </>
                                        }
                                    </li>
                                </ul>
                            </Offcanvas.Body>
                        </Offcanvas>

                    </div>

                </div>

            </div>

        </>
    );
};

export default Header;