import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import { Table } from 'react-bootstrap';
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from '../../config/api';
import dateformat from "dateformat";
import { useLocation, useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import { Description } from '@mui/icons-material';
import NoResult from './NoResult';

const DirectIncome = (props: any) => {
    const { t, i18n } = useTranslation();

    const { type } = useParams();
    let location = useLocation();



    const [directIncome, setDirectIncome] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [levelSearch, setLevelSearch] = useState('');
    const [searchedType, setSearchType] = useState('level');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [allLevels, setAllLevels] = useState([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
    ]);
    const [keyCount, setKeycount] = useState(0);

    useEffect(() => {
        getIncomeReport();

    }, [levelSearch, page, type]);

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
        setStartDate('');
        setEndDate('')
    }, [type]);

    const getIncomeReport = async () => {
        setLoading(true);
        if (levelSearch) {
            if (startDate) {
                var searchData = `&startDate=${startDate}&endDate=${endDate}&level=${levelSearch}`;

            } else {
                var searchData = `&level=${levelSearch}`;
            }
        } else {
            var searchData = "";
        }
        if (startDate) {
            var searchData = `&startDate=${startDate}&endDate=${endDate}`;

        } else {
            var searchData = "";

        }
        if(type == 'level_income'){
        var res = await _fetch(`${api_url}report/incomes?type=${type}&limit=10&page=${page}${searchData}`, "GET", {})
        }else{
        var res = await _fetch(`${api_url}report/incomes-v2?type=${type}&limit=10&page=${page}${searchData}`, "GET", {})

        }
        if (res?.status === "success") {
            setLoading(false);
            setDirectIncome(res?.data?.data);
            setTotalRecord(res?.total);
            setResult(res?.results);
            setTotalAmount(res?.totalAmount);
        }

    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };

    const refresh = () => window.location.reload();

    return (
        <main>

            <div className='coustom_container'>
                <div className="uni-level-outer">
                    <div className="level_inner common_card_css loader_main">
                        {loading &&
                            <div className="spinner_bg">

                                <div className="color_ring">  <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"

                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                /></div>

                            </div>
                        }
                        <div className="top_section_unilevel">
                            <div className="date">
                                <label className='form_lable'>{t("From")}</label>   <input type='date' className="form-control h-auto" placeholder='' onChange={(e: any) => setStartDate(e.target.value)} value={startDate} />
                            </div>

                            <div className="date">
                                <label className='form_lable'>To</label>   <input type='date' className="form-control h-auto" placeholder='' onChange={(e: any) => setEndDate(e.target.value)} value={endDate} />
                            </div>
                            {type == 'level_income' && <div className='date'>
                                <label className='form_lable'>{t("Level")}</label>
                                <select className="form-control h-auto select_option" onChange={(e: any) => setLevelSearch(e.target.value)} >
                                    <option value="">All</option>

                                    {allLevels?.map((item: any, key: any) => (
                                        <option key={key} value={item}> {t('Level') + " " + item} </option>
                                    ))}
                                    {/* <option value="0">Level</option>
                                    <option value="1">All</option>
                                    <option value="2">Level 1</option>
                                    <option value="3">Level 2</option>
                                    <option value="4">Level 3</option> */}
                                </select>
                            </div>}
                            <button type='button' className='new_button_css_dashboard' onClick={() => getIncomeReport()}>{t("Search")}</button>

                            <p className='form-control h-auto m-0'>
                                {location.pathname == '/report/level_income' && <span> {t("Level  Bouns")}</span>}
                                {location.pathname == '/report/loyalty_club_pool1' && <span> {t("Loyalty Club Pool 1")}</span>}
                                {location.pathname == '/report/loyalty_club_pool2' && <span> {t("Loyalty Club Pool 2")}</span>}
                                {location.pathname == '/report/loyalty_club_pool3' && <span> {t("Loyalty Club Pool 3")}</span>} 
                                : <span>${totalAmount?.toFixed(2)}</span></p>

                            <div className="form-btn">
                                <button className="new_button_css_dashboard" onClick={refresh} >
                                    <ReplayIcon />
                                </button>
                            </div>
                        </div>
                        <div className="table_section mt-4">
                            <div className="scrolling_table">
                                <div className="scroll_under">
                                    <div className="table_responsive">
                                        <Table className="support_table all_tables">
                                            <thead className='thead_dashboard'>
                                                <tr className="table_heading_row">
                                                    <th>{t("Amount")}</th>
                                                    {type == 'level_income' && <th>{t("Level")}</th>}
                                                    {/* {type == 'level_income' || type == 'direct_income' && <th>{t("From")}</th>} */}
                                                    <th>{t("Description")}</th>
                                                    <th>{t("Type")}</th>
                                                    <th>{t("Credit Type")}</th>
                                                    <th>{t("Created Date")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody_dashboard'>
                                                {directIncome.length == 0 ? <tr className='no_data_tr'>
                                                    <td colSpan={9}>
                                                        <NoResult />
                                                    </td>
                                                </tr> :

                                                    <>
                                                        {directIncome?.map((item: any, index: any) => {
                                                            return (
                                                                <>

                                                                    <tr className="table_data_row">
                                                                        <td className="table_data text-success">${item?.amount?.toFixed(2)}</td>
                                                                        {type == 'level_income' && <td className="table_data">{item?.level}</td>}
                                                                        {/* {type == 'level_income' || type == 'direct_income' && <td className="table_data">
                                                                            <div className="pic_text"> {item?.from} </div> </td>} */}
                                                                        <td className="table_data">{item?.description}</td>
                                                                        <td className="table_data">
                                                                            {type === 'direct_income' ? "Direct Profit" : type === 'level_income' ? "Layered Investment Returns" : type === 'roi_income' ? "Profit on Investment" : type === 'royalty_income' ? "Royalty Profit" : type === 'global_lifetime_pool_income' ? "Eternal Global Pool Profit" : ""}
                                                                        </td>
                                                                        <td className="table_data">{item?.amount > 0 ? "Credit" : "Debit"}</td>
                                                                        <td className="table_data payment_fee">
                                                                            {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}</>
                                                }
                                            </tbody>
                                        </Table>
                                        {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={1}
                                            pageCount={totalRecord / 10}
                                            previousLabel="<"
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default DirectIncome