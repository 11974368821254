import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import banner_1 from "../../Assets/homepage/slide-side1.png"
import { Col, Row } from 'react-bootstrap';
import _Img_about from '../../Assets/homepage/about-us.jpeg';
import __img_you from '../../Assets/homepage/you_1.jpeg';
import _img_service_1 from '../../Assets/homepage/serv-1.jpg';
import _img_service_2 from '../../Assets/homepage/serv-2.jpg';
import _img_service_3 from '../../Assets/homepage/serv-3.jpg';
import __img_client from '../../Assets/homepage/tetsi-1.jpeg';
import ProgressBar from "@ramonak/react-progress-bar";
import { ArrowForward, AttachMoney, Star } from '@mui/icons-material';
import { title } from '../../config/Config';


const Index = () => {


  var auth = localStorage.getItem("auth");


  return (
    <>

      <section className='four-banner'>
        <div className='four-index-container h-100'>
          <div className='row h-100'>
            <div className='col-lg-6 mySwiper_coumn'>
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className='__slider'>
                    <div className='four-index-container'>
                      <p className='__top'>protecting what matters!</p>
                      <h1 className='__heading'> start or grow your own business</h1>
                      <h6 className='__pragraph'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab earum suscipit quasi excepturi quae. Voluptatem laborum, beatae architecto adipisci illo nulla eaque veritatis quaerat quos voluptates, earum numquam. Quos, autem?</h6>

                      <div className='__btn'>
                        {auth ?
                          <Link className='btn' to="/dashboard"> Dashboard</Link>
                          :
                          <Link className='btn' to="/login"> Get Start</Link>
                        }
                        <Link className="btn" to="#footer"> Contact</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className='__slider'>
                    <div className='four-index-container'>
                      <p className='__top'>protecting what matters!</p>
                      <h1 className='__heading'> start or grow your own business</h1>
                      <h6 className='__pragraph'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab earum suscipit quasi excepturi quae. Voluptatem laborum, beatae architecto adipisci illo nulla eaque veritatis quaerat quos voluptates, earum numquam. Quos, autem?</h6>

                      <div className='__btn'>
                        {auth ?
                          <Link className='btn' to="/dashboard"> Dashboard</Link>
                          :
                          <Link className='btn' to="/login"> Get Start</Link>
                        }
                        <Link className="btn" to="#footer"> Contact</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>
            <div className='col-lg-6 right_img_banner'>
              <div className='__img__sec'>
                <img src={banner_1}  alt='Banner_img'/>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='four-about'>
        <div className='four-index-container'>
          <Row>
            <Col lg={6}>
              <div className='__leftcard'>
                <img src={_Img_about} alt='img' />

                <div className='__boxs'>
                  <span> +1</span>
                  <br />
                  Years 0f
                  Experience
                </div>
              </div>
            </Col>


            <Col lg={6}>
              <div className='__rightcard'>
                <p className='subHeding'> <span></span>ABOUT US</p>

                <h3 className='__heading'>We Have Been Working Very Efficiently With Loan.</h3>

                <p className='__pragraph'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form injected.</p>

                <div className='progress__sec'>
                  <h5>Advanced Analytics</h5>
                  <ProgressBar
                    bgColor="#00008b"
                    animateOnRender={true}
                    completed={70} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className='four_details'>
        <div className='four-index-container'>
          <Row>
            <Col sm={4}>
              <div className='Icon'>
                <p className='icon_img'> <AttachMoney /> </p>
                <div className='Icon_data'>
                  <h3> + 18264</h3>
                  <p> Loans Increase</p>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className='Icon'>
                <p className='icon_img'> <AttachMoney /> </p>
                <div className='Icon_data'>
                  <h3> + 18264</h3>
                  <p> Loans Increase</p>
                </div>
              </div>
            </Col>

            <Col sm={4}>
              <div className='Icon'>
                <p className='icon_img'> <AttachMoney /> </p>
                <div className='Icon_data'>
                  <h3> + 18264</h3>
                  <p> Loans Increase</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>


      <section className='four_you'>
        <div className='four-index-container'>
          <Row>


            <Col lg={7}>
              <div className='__you__right'>
                <p className='subHeding'> <span></span>WHAT WE DO</p>

                <h3 className='__heading'>We Have Been Working Very Efficiently With Loan.</h3>

                <p className='__pragraph'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form injected.</p>

                <p className='__pragraph'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form injected.</p>

                <div className='__btn'>
                  {auth ?
                    <Link className='btn' to="/dashboard"> Dashboard</Link>
                    :
                    <Link className='btn' to="/login"> Get Start</Link>
                  }
                  <Link className="btn" to="#footer"> Contact</Link>
                </div>

              </div>
            </Col>

            <Col lg={5}>
              <div className='__you_left'>
                <img src={__img_you} alt='img' />
              </div>
            </Col>
          </Row>
        </div>
      </section>


      <section className='service_you'>
        <div className='four-index-container'>
          <Row className='row__d'>
            <Col sm={12}>
              <div className='__top_header'>
                <div className='__left'>
                  <p className='subHeding'> <span> </span> WHAT WE DO</p>
                  <h2>Professional Services</h2>
                </div>

                <Link to='#' className='btn'> Learn More</Link>
              </div>
            </Col>

            <Col lg={4} sm={6}>
              <div className='__serviceCard card'>

                <div className='card-header'>
                  <h4>Agricultural</h4>
                  <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>

                  <Link to='#' className='link'> Read More <ArrowForward /> </Link>
                </div>

                <div className='Img__src'>
                  <img src={_img_service_1} alt='service_1' />
                </div>
              </div>
            </Col>

            <Col lg={4} sm={6}>
              <div className='__serviceCard card'>

                <div className='card-header'>
                  <h4>Business</h4>
                  <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>

                  <Link to='#' className='link'> Read More <ArrowForward /> </Link>
                </div>

                <div className='Img__src'>
                  <img src={_img_service_2} alt='service_1' />
                </div>
              </div>
            </Col>

            <Col lg={4} sm={6}>
              <div className='__serviceCard card'>

                <div className='card-header'>
                  <h4>Agricultural</h4>
                  <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>

                  <Link to='#' className='link'> Read More <ArrowForward /> </Link>
                </div>

                <div className='Img__src'>
                  <img src={_img_service_3} alt='service_1' />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className='four-clients'>
        <div className='four-index-container'>

          <div className='__center'>
            <p className='subheading'> <span> </span>TESTIMONIALS <span> </span></p>
            <h4>What Our Clients Say</h4>

            <div className='datas'>
              <div className='Img__sec'>
                <img src={__img_client} alt="clients" />
              </div>

              <div className='Text__sec'>

                <div className='ratings'> 
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
                </div>

                <h5>Success is no accident. It is hard work, perseverance, learning, studying, sacrifice and most of all, love of what you are doing or learning to</h5>

                <p className='__name'> {title} <span> Manager</span></p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='service_you'>
        <div className='four-index-container'>
          <Row>
            <Col sm={12}>
              <div className='__top_header justify-content-center'>
                <div className='__left'>
                  <p className='subHeding'> <span> </span> NEWS & UPDATES <span> </span></p>
                  <h2>Latest From Our Blog</h2>
                </div>

              </div>
            </Col>

            <Col lg={4} sm={6}>
              <div className='__serviceCard card'>

                <div className='card-header'>
                  <h4>Agricultural</h4>
                  <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>

                  <Link to='#' className='link'> Read More <ArrowForward /> </Link>
                </div>

                <div className='Img__src'>
                  <img src={_img_service_1} alt='service_1' />
                </div>
              </div>
            </Col>

            <Col lg={4} sm={6}>
              <div className='__serviceCard card'>

                <div className='card-header'>
                  <h4>Business</h4>
                  <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>

                  <Link to='#' className='link'> Read More <ArrowForward /> </Link>
                </div>

                <div className='Img__src'>
                  <img src={_img_service_2} alt='service_1' />
                </div>
              </div>
            </Col>

            <Col lg={4} sm={6}>
              <div className='__serviceCard card'>

                <div className='card-header'>
                  <h4>Agricultural</h4>
                  <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>

                  <Link to='#' className='link'> Read More <ArrowForward /> </Link>
                </div>

                <div className='Img__src'>
                  <img src={_img_service_3} alt='service_1' />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  )
}

export default Index
