import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { api_url, titleDescription } from "../../config/Config";
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getData } from 'country-list';
import Authenticator from "./Authenticator";
import { useTranslation } from 'react-i18next';
import Loader from "../../config/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select, { SingleValue } from 'react-select';


interface base64 {
  base64: string;
}

const Profile = (props: any) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { selectedTab } = location.state || {};

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);


  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  type CountryOption = {
    value: string;
    label: string;
    flagUrl: string;
  };

  const CustomOption: React.FC<{ innerProps: any; label: string; data: CountryOption }> = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img src={data.flagUrl} alt={label} style={{ marginRight: '8px' }} />
      {label}
    </div>
  );

  const countryOptions: CountryOption[] = getData().map((country) => ({
    value: country.code,
    label: country.name,
    flagUrl: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`
  }));

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [bio, setBio] = useState('');
  const [bep20Address, setBEP20Address] = useState('');
  const [trc20Address, setTRC20Address] = useState('');
  const [bep20AddressShow, setBEP20AddressShow] = useState('');
  const [trc20AddressShow, setTRC20AddressShow] = useState('');
  const [isOTP, setOTP] = useState('');
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');

  const [countryLabel, setCountryLabel] = useState('');
  const [countryFlagUrl, setCountryFlagUrl] = useState('');
  const [country, setCountry] = useState<CountryOption | null>({ value: '', label: countryLabel, flagUrl: countryFlagUrl });

  const [isAvatar, setAvatar] = useState<string>("");
  const [showtxt, setshowtxt] = useState(true);


  const handleTogglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const [password3, setPassword3] = useState("");
  const [showPassword3, setShowPassword3] = useState(false);

  const handleTogglePassword3 = () => {
    setShowPassword3(!showPassword3);
  };



  const Navigate = useNavigate();

  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
    getUserInfo();
  }, []);

  useEffect(() => {
    setCountry({
      value: '',
      label: countryLabel,
      flagUrl: countryFlagUrl
    });
  }, [countryLabel, countryFlagUrl]);

  const getUserInfo = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}user/userInfo/`, "GET", {});

    if (res?.status === "success") {
      // console.log("here", res?.data?.data?.phone);

      setLoading(false);
      setFirstName(res?.data?.data?.name);
      setFullName(res?.data?.data?.name);
      // setAvatar(res?.data?.data?.profile_image);
      setPhone("+" + res?.data?.data?.phone);
      setAvatar(res?.data?.data?.display_profile);
      // setPhone(res?.data?.data?.phone);
      setAddress(res?.data?.data?.address);
      setBio(res?.data?.data?.bio);
      setBEP20Address(res?.data?.data?.wallet_address);
      setTRC20Address(res?.data?.data?.wallet_addresses?.TRC20);
      setBEP20AddressShow(res?.data?.data?.wallet_address);
      setTRC20AddressShow(res?.data?.data?.wallet_addresses?.TRC20);
      setCountry({
        value: '',
        label: res?.data?.data?.country,
        flagUrl: res?.data?.data?.country_flag
      });
    }
  }

  const [profileOTP, setprofileOTP] = useState("");

  const updateProfile = async () => {
    setbtnDisable(true);
    let data = {
      "name": firstName,
      "phone": phone?.replace('+',''),

      "address": address,
      "bio": bio ? bio : "bio",
      "otp": profileOTP,
      "country": country?.label,
      "country_flag": country?.flagUrl,
    }
    // { name, phone, address, bio, country, country_flag }

    let d = await _fetch(`${api_url}auth/profileUpdate`, "POST", data, {})
    if (d?.status === "success") {
      toasted.success(d?.message);
      setprofileOTP("");
      getUserInfo();
      setbtnDisable(false);

    }
    else {
      setbtnDisable(false);
      toasted.error(d?.message);
    }

  }

  const [passwordBtnDisable, setpasswordBtnDisable] = useState(false)

  const updatePassword = async () => {
    setpasswordBtnDisable(true);
    let data = {
      "current_password": password,
      "new_password": password2,
      "confirm_new_password": password3
    }
    let result = await _fetch(`${api_url}user/changePassword`, "POST", data, {})
    if (result?.status === "success") {
      toasted.success(result?.message);
      Navigate("/login");
      setpasswordBtnDisable(false);
    }
    else {
      toasted.error(result?.message);
      setpasswordBtnDisable(false);
    }
  }

  const [walletOTP, setwalletOTP] = useState();
  const [walletBtnDisable, setwalletBtnDisable] = useState(false);

  const updateWalletAddress = async () => {
    setwalletBtnDisable(true);
    let data = {
      "bep20": bep20Address,
      // "trc20": trc20Address,
      "otp": walletOTP
    }
    let resvalue = await _fetch(`${api_url}auth/walletaddressupdate`, "POST", data, {})
    if (resvalue?.status === "success") {
      toasted.success(resvalue?.message);
      getUserInfo();
      setwalletBtnDisable(false);
    }
    else {
      setwalletBtnDisable(false);
      toasted.error(resvalue?.message);
    }
  }

  const handleFirstNameChange = (event: any) => {
    let inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(inputValue)) {
      setFirstName(inputValue);
    }
  }

  const handleLastNameChange = (event: any) => {
    let inputValue = event.target.value;
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(inputValue)) {
      setLastName(inputValue);
    }
  }


  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  // const handleFileRead = async (event: any) => {
  //   const file = event.target.files[0]
  //   let base64 = await convertBase64(file)
  //   setAvatar(String(base64));
  // }
  //profile_image
  const imageUpload = async (event: any) => {
    const file = event.target.files[0]
    let base64 = await convertBase64(file)

    let postData = {
      "profile_image": String(base64)
    }

    let result = await _fetch(`${api_url}auth/profileImageUpdate`, "POST", postData, {})
    if (result?.status === "success") {
      toasted.success(result?.message);
      getUserInfo();
    } else {
      toasted.error(result?.message)
    }

  }



  const [timer, setTimer] = useState('00:00');
  const sendOTP_ = async () => {
    var response = await _fetch(`${api_url}auth/generateOTP?check=${userEmail}&email_type=email`, 'GET', {}, {});

    toasted.success(response?.message)
    if (timer === "00:00") {
      var seconds: any = '180';
      var intervalId = window.setInterval(function () {
        seconds--;

        // if (seconds > 1) {
        let minutes: any = Math.floor(seconds / 60);
        let extraSeconds: any = seconds % 60;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
        var leftData = minutes + ":" + extraSeconds;

        setTimer(leftData);
        if (seconds == 0) {
          stop();
        }
      }, 1000);
    }
    let stop = () => clearInterval(intervalId);

  }


  const [tabPhone, setTabPhone] = useState(false);


  // const tabView = () => {
  //   setTabPhone(!tabPhone);
  // };

  console.log('isAvatar',isAvatar);
  
  return (
    <main>
      <Tabs defaultIndex={selectedTab === "Wallet" ? 2 : 0}>
        <div className="coustom_container">




          <div className="kt_profile_datas">

            <div className="kt_profile_top">
              <div className="default-block__title --border-bottom">{t("My Profile")} </div>

              <div className="row">
                <div className="col-xl-4">
                  <div className="__left_tab">
                    <div className="profile-infos">
                      <div className="name-pic">
                        <div className="outer_profile">


                          <div className="left_profile">

                            {(isAvatar == "" || isAvatar == null) && <label htmlFor="upload">
                              <div className="emptyPlaceholder text-center">
                                <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                  <path d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z">
                                  </path>
                                </svg>
                                <h6 >{t("Upload photo")}</h6>
                              </div>
                              <span>
                                <input type="file" id="upload" style={{ display: "none" }} onChange={(event: any) => { imageUpload(event); }} /> </span>
                            </label>
                            }

                            {isAvatar && <img src={isAvatar} alt="user-icon" width="100%" />}

                          </div>
                        </div>



                        <div className="name-post">
                          <h5> {fullName ? fullName : "Loading..."}</h5>
                        </div>
                      </div>

                      <div className="name-post">
                        <h6>{t("About")} :  {bio ? bio : "-"}</h6>
                      </div>

                    </div>

                    <div className="kt_heading__datas mt-5">
                      <TabList>
                        <Tab>{t("Account")}</Tab>
                        <Tab>{t("Password")}</Tab>
                        {/* <Tab>{t("Wallet")}</Tab> */}
                        <Tab>{t("Authenticator App")}</Tab>
                      </TabList>
                    </div>
                  </div>
                </div>

                <div className="col-xl-8">
                  <TabPanel>
                    <div className="accout-seting">
                      <div className="default-block__title --border-bottom">{t("Account Setting")}</div>

                      <div className="bottom_sec">
                        <div className="row">
                          <Col md={12}>
                            <div className="form">
                              <label className="form-label form_lable">{t("First Name")}</label>
                              <input className="form-control" type="text" placeholder=" Name" onChange={(e) => handleFirstNameChange(e)} value={firstName } />
                            </div>
                          </Col>

                          <Col lg={12}>
                            <div className="form">
                              <label className="form-label form_lable">Phone </label>
                              <PhoneInput
                                inputStyle={{ height: '50px', padding: '0px 20px 0px 50px', marginBottom: '10px', border: '1px solid rgba(99, 111, 125, 0.3)', borderRadius: '10px', outline: 'none', color: '#000', width: '100%', fontSize: '14px' }}
                                containerStyle={{}}
                                searchClass="search-class"
                                disableSearchIcon={false}
                                enableTerritories
                                countryCodeEditable={true}
                                placeholder='Phone Number'
                                buttonStyle={{ width: '47px' }}
                                dropdownStyle={{ height: '300px' }}
                                country={"in"}
                                value={phone}
                                onChange={(value: string) => setPhone(value)}
                                enableSearch={true} />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="form__seciotn">

                              <div className="form">
                                <label className="form-label">{t("Country")}</label>
                                <Select
                                  options={countryOptions}
                                  value={country}
                                  onChange={(option: SingleValue<CountryOption>) => setCountry(option)}
                                  components={{ Option: CustomOption }}
                                  isSearchable
                                  placeholder="Select a country..."
                                // defaultValue={country}
                                />

                              </div>
                            </div>

                          </Col>

                          <Col md={12}>
                            <div className="form mb-0">
                              <label className="form-label form_lable">{t("Address")} </label>
                              <textarea className="form-control" placeholder='Address' rows={5} id='address' onChange={(e: any) => setAddress(e.target.value)} value={address ? address : ""} ></textarea>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="form mb-0">
                              <label className="form-label form_lable">{t("Bio")} </label>
                              <textarea className="form-control" placeholder='Bio' rows={5} id='address' onChange={(e: any) => setBio(e.target.value)} value={bio ? bio : ""} ></textarea>
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="form">
                              <label className="form-label form_lable">{t("Auth code")}</label>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Auth Code"
                                onChange={(e: any) => setprofileOTP(e.target.value)}
                                value={profileOTP}
                              />
                            </div>
                          </Col>
                        </div>

                      </div>


                      <div className="d-flex align-items-center justify-content-center mt-5">
                        <button className="new_button_css_dashboard" onClick={updateProfile} disabled={btnDisable}>
                          {btnDisable === true &&
                            <Loader LoaderTxt={'Please Wait...'} />
                          }
                          {btnDisable === false && `${t("Update")}  ${t("Account Setting")}`}
                        </button>
                      </div>

                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="accout-seting password__settings">
                      <div className="default-block__title --border-bottom">{t("Password Setting")}</div>
                      <Row className="password__settings">
                        <Col lg="6">
                          <label className="form_lable">{t("Old Password")}</label>
                          <div className="hide-pass">
                            <input type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="form-control" />

                            <button type="button" onClick={handleTogglePassword} >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </button>
                          </div>
                        </Col>

                        <Col lg="6">
                          <label className="form_lable">{t("New Password")}</label>
                          <div className="hide-pass">
                            <input type={showPassword2 ? "text" : "password"} value={password2} onChange={(e) => setPassword2(e.target.value)} placeholder="Password" className="form-control" />

                            <button type="button" onClick={handleTogglePassword2} >
                              {showPassword2 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </button>
                          </div>
                        </Col>

                        <Col lg="12">
                          <label className="form_lable">{t("Confirm New Password")}</label>
                          <div className="hide-pass">
                            <input type={showPassword3 ? "text" : "password"} value={password3} onChange={(e) => setPassword3(e.target.value)} placeholder="Password" className="form-control" />
                            <button type="button" onClick={handleTogglePassword3} >
                              {showPassword3 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </button>
                          </div>
                        </Col>

                        <div className="d-flex align-items-center justify-content-center mt-5">
                          <button className="new_button_css_dashboard" onClick={updatePassword} disabled={passwordBtnDisable}>
                            {passwordBtnDisable === true &&
                              <Loader LoaderTxt={'Please Wait...'} />
                            }
                            {passwordBtnDisable === false && `${t("Update")} ${t("Password")}`}
                          </button>
                        </div>
                      </Row>
                    </div>
                  </TabPanel>

                  {/* <TabPanel>
                    <div className="accout-seting">
                      <div className="default-block__title --border-bottom">{t("Wallet")}</div>
                      <div className="form">
                        <label className="form-label form_lable">BEP20</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="BEP20 Address"
                          onChange={(e) => setBEP20Address(e.target.value)}
                          value={bep20Address}
                        />

                      </div>

                      <div className="form mt-4">
                        <label className="form-label form_lable">{t("Auth code")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Auth Code"
                          onChange={(e: any) => setwalletOTP(e.target.value)}
                          value={walletOTP}
                        />

                      </div>
                     

                      <div className="d-flex align-items-center justify-content-center mt-5">

                        <button className="new_button_css_dashboard" onClick={updateWalletAddress} disabled={walletBtnDisable}>
                          {walletBtnDisable === true &&
                            <Loader LoaderTxt={'Please Wait...'} />
                          }
                          {walletBtnDisable === false && `${t("Update")} ${t("Wallet")}`}
                        </button>

                      </div>
                    </div>
                  </TabPanel> */}

                  <TabPanel>
                    <Authenticator />
                  </TabPanel>
                </div>
              </div>



            </div>






          </div>
        </div>
      </Tabs>
    </main>
  );
};

export default Profile;
