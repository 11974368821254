import React from 'react'
import gif from "../../Assets/new_images/no_result.gif"
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const NoResult = () => {
    return (
        <div>
            <div className="no_result_found_sec">
                <img src={gif} alt="" />
                <h5>Sorry! No Result Found <SentimentVeryDissatisfiedIcon /></h5>
                <p>We'er sorry what you were looking for. Please try another way</p>
            </div>
        </div>
    )
}

export default NoResult
