import { Row, Col, Form, InputGroup, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import _fetch from "../../config/api";
import { api_url, logo, title, titleDescription } from "../../config/Config";
import toasted from "../../config/toast";
import Loader from "../../config/Loader";
import __img_login from "../../Assets/homepage/loginsec.svg";
import { ArrowDropDownRounded, CheckCircleRounded, ErrorOutlineRounded } from "@mui/icons-material";

const Login = (props: any) => {

  const [btnDisable, setbtnDisable] = useState(false);



  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const Navigate = useNavigate();

  const [password, setPassword] = useState("");
  // const [otp, setotp] = useState();
  const [username, setUsername] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [selectCheckbox, setSelectCheckbox] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const submitLogin = async (e: any) => {
    setbtnDisable(true)
    e.preventDefault();
    let data = {
      "email": username,
      "password": password,
      // "otp": otp
    }
    let res = await _fetch(`${api_url}auth/login`, "POST", data, {})
    // if(selectCheckbox) {

    if (res?.status === 'success') {
      // if (res?.data?.user?.verified == true) {
      localStorage.setItem("auth", 'true');
      localStorage.setItem("accessToken", res?.token);
      localStorage.setItem("user", JSON.stringify(res?.data?.user));
      toasted.success(res?.message);
      Navigate("/dashboard");
      // } else {
      //   Navigate("/waiting-conformation", { state: res?.data?.user?.user_info?.name });
      // }

      setbtnDisable(false)

    }
    else {
      localStorage.clear();
      setbtnDisable(false)
      toasted.error(res?.message);
      // }
    }
    // else {
    //   toasted.error("Please select checkbox");
    // }
  }

  // const [timer, setTimer] = useState('00:00');
  // const sendOTP_ = async () => {
  //   var response = await _fetch(`${api_url}auth/generateAuthOtp`, 'POST', { "username": username }, {});

  //   toasted.success(response?.message)
  //   if (timer === "00:00") {
  //     var seconds: any = '180';
  //     var intervalId = window.setInterval(function () {
  //       seconds--;

  //       // if (seconds > 1) {
  //       let minutes: any = Math.floor(seconds / 60);
  //       let extraSeconds: any = seconds % 60;
  //       minutes = minutes < 10 ? "0" + minutes : minutes;
  //       extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
  //       var leftData = minutes + ":" + extraSeconds;

  //       setTimer(leftData);
  //       if (seconds == 0) {
  //         stop();
  //       }
  //     }, 1000);
  //   }
  //   let stop = () => clearInterval(intervalId);

  // }


  // const [validated, setValidated] = useState(false);

  // const handleSubmit = (event: any) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }

  //   setValidated(true);
  // };

  const [hasFocused, setHasFocused] = useState(false);


  const containsAtSymbol = () => {
    return username.includes('@gmail.com');
  };


  const isInputLongEnough = () => {
    return username.trim().length >= 4;
  };

  const handleFocus = () => {
    setHasFocused(true);
  };



  return (
    <>

      <div className="__auths">
        <div className="__authcard">
          <div className="row">

            <div className="col-lg-5 IMg__src_out">
              <div className="IMg__src">
                <div>
                  <div className="__img">
                    <img src={__img_login} alt="img" />

                  </div>
                  <h5> sign-in</h5>
                  <p> Secure online accounts with OneAuth 2FA. Back up OTP secrets and never lose access to your accounts.</p>
                </div>

                <h6>Don't have a {title} account?  <br /> <Link to="/register">Sign up now</Link></h6>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="__right_">
                <div className="card-header">
                  <Link to="/"> <img src={logo} alt={title + "logo"} /></Link>

                  <button type="button" className="btn"> ENG <ArrowDropDownRounded /> </button>
                </div>

                <div className="card-body">
                  <h5>Sign in to Your Account</h5>
                  <p> Please sign in to enter in {title}</p>

                  <div className="Input_area">

                    <Form className="form-start">

                      <Form.Group className="__inputGp">
                        <input type="text" placeholder="Enter Email Address" onChange={(e: any) => setUsername(e.target.value)} value={username} onFocus={handleFocus} />

                        {hasFocused && (!isInputLongEnough() || !containsAtSymbol()) ? (
                          <div>
                            <OverlayTrigger
                              key={"left"}
                              placement={"left"}
                              overlay={
                                <Tooltip id="tooltip-left">
                                  <span> Please Enter Your Email Address </span>
                                </Tooltip>
                              }
                            >
                              <span className="__message text-danger"> <ErrorOutlineRounded /> </span>
                            </OverlayTrigger>
                          </div>


                        ) : (

                          hasFocused && isInputLongEnough() && containsAtSymbol() && (
                            <span className="__message text-success"  ><CheckCircleRounded /></span>
                          )
                        )}
                      </Form.Group>

                      <div className="hide-pass">
                        <input className="input_pass" type={showPassword ? "text" : "password"} value={password} onChange={(e: any) => setPassword(e.target.value)} placeholder="Enter Password" />

                        <button type="button" className="btn__sc" onClick={handleTogglePassword}>
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </button>
                      </div>

                      <div className="login-remfor">
                        <div className="login-check">
                          <input type="checkbox" id="33dddd" required />
                          <label className="me-label ms-1" htmlFor="33dddd" >Remember me</label>
                        </div>
                        <Link to="/forgot">Forgot Password</Link>
                      </div>


                      <div className="btn__s">
                        <button type="submit" className="log-btn" onClick={submitLogin} disabled={btnDisable}>
                          {btnDisable === true &&
                            <Loader />
                          }
                          {btnDisable === false && <span className="btnText">sign in</span>}
                        </button>
                      </div>

                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Login;
