import React from 'react';
import './Assets/Scss/Style.scss';
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";
import Login from './Public/Users/Login';
import Register from './Public/Users/Register';
import Forgot from './Public/Users/Forgot';
import Dashboard from './Public/HomePage/Dashboard';
import Profile from './Public/HomePage/Profile';
import Trading from './Public/HomePage/Trading';
import Team from './Public/HomePage/Team';
import Overview from './Public/HomePage/Overview';
import Ledger from './Public/HomePage/Ledger';
import PayHistory from './Public/HomePage/PayHistory';
import Footer from './Layout/Footer';
import Market from './Private/Market';
import Sidebar from './Layout/Sidebar';

import Deposit from './Public/HomePage/Deposit';
import PrivateRoutes from './config/PrivateRoutes';
import BuyInfra from './Public/HomePage/BuyInfra';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UniLevel from './Public/HomePage/UniLevel';
import Cashflow from './Public/HomePage/Cashflow';
import Presentations from './Public/HomePage/Presentations';
import Support from './Public/HomePage/Support';
import Demo from './Public/Demo';
import Index from './Public/index_page/Index';
import TicketView from './Public/HomePage/TicketView';
import PayoutRequest from './Public/HomePage/PayoutRequest';
import DirectIncome from './Public/HomePage/directIncome';
import { LanguageProvider } from "../src/LanguageContext";
import MainOverview from './Public/HomePage/MainOverview';
import WaitingScreen from './Public/WaitingScreen';
import ApprovedScreen from './Public/ApprovedScreen';
import Forgotconformation from './Public/Forgotconformation';
import AdminLogin from './Public/Users/AdminLogin';
import HomeHeader from './Public/HomeHeader';
import Header from './Layout/Header';
import HomeFooter from './Public/HomeFooter';
import AllReports from './Public/HomePage/AllReports';
import Success from './Public/Users/Success';
import ComingSoon from './Public/ComingSoon';
import PinsReport from './Public/HomePage/PinsReport';
import Fund from './Public/HomePage/Fund';
import WIthdraw from './Public/HomePage/WIthdraw';
import AdminUserLogin from './Public/Users/AdminUserLogin';
import WalletHistory from './Public/HomePage/WalletHistory';
import UpdateWalletAddress from './Public/HomePage/UpdateWalletAddress';
import DepositHistory from './Public/HomePage/DepositHistory';
import TransFundHistory from './Public/HomePage/TransFundHistory';
import EWalletHistory from './Public/HomePage/EWalletHistory';

function App() {

  // ? Login page.....
  function LoginLayout() {
    return (
      <>
        <Outlet />
      </>
    );
  }

  // ? Login page.....
  function VerificationLayout() {
    return (
      <>
        <Outlet />
        {/* <PublicBottomBar/> */}
      </>
    );
  }

  function PublicOuter() {
    return (
      <>
        <HomeHeader />
        <Outlet />
        <HomeFooter />
      </>
    );
  }

  // Public page.....
  function PublicLayout() {
    return (
      <>
        <Sidebar />
        <Header />
        <Outlet />
        <Footer />

      </>
    );
  }

  // Only Private page.....
  function PrivateLayout() {
    return (
      <>
        <LanguageProvider>
          <Sidebar />
          <Header />
          <Outlet />
          <Footer />
        </LanguageProvider>
      </>
    );
  }

  return (
    <>
      <Router>
        <LanguageProvider>
          <Routes>

            {/* header and footer is removed this layout */}

            <Route element={<PublicOuter />}>
              <Route path='*' element={<ComingSoon />} />
              <Route path='/' element={<ComingSoon />} />
              <Route path='/homepage' element={<Index />} />

              {/* <Route path='/about' element={<About />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms-conditions' element={<Terms />} />
              <Route path='/contact-us' element={<ContactUs />} /> */}
              {/* <Route path='/coming-soon' element={<ComingSoon />} /> */}

            </Route>

            <Route element={<VerificationLayout />}>
              <Route path='/successfull' element={<Success />} />


              <Route path='/waiting-conformation' element={<WaitingScreen />} />
              <Route path='/verification/:token' element={<ApprovedScreen />} />
              <Route path='/resetPassword/:token' element={<Forgotconformation />} />
              <Route path='/admin-login/:email/:pass' element={<AdminUserLogin />} />

            </Route>

            <Route element={<LoginLayout />}>
              <Route path='/login' element={<Login pageTitle={`Login`} />} />
              <Route path='/admin-login' element={<AdminLogin pageTitle={`Admin Login`} />} />

              <Route path='/register' element={<Register pageTitle={`Register`} />} />
              <Route path='/register/:sponser' element={<Register pageTitle={`Register`} />} />
              <Route path='/register/:sponser/:getPin' element={<Register pageTitle={`Register`} />} />

              <Route path='/forgot' element={<Forgot pageTitle={`Forgot`} />} />
              <Route path='/demo' element={<Demo />} />
            </Route>

            {/* All Public pages___ */}
            <Route element={<PrivateRoutes />}>

              <Route element={<PublicLayout />}>
                <Route path='/dashboard' element={<Dashboard pageTitle={`Dashboard`} />} />
                <Route path='/profile' element={<Profile pageTitle={`Profile`} />} />
                <Route path='/deposit' element={<Deposit pageTitle={`Deposit`} />} />
                <Route path='/trading-profit' element={<Trading pageTitle={`Buy History`} />} />
                <Route path='/buy-infra' element={<BuyInfra pageTitle={`Buy Infra`} />} />
                <Route path='/team' element={<Team pageTitle={`Team`} />} />
                <Route path='/uni-level' element={<UniLevel pageTitle={`Uni Level`} />} />
                <Route path='/report/:type' element={<DirectIncome pageTitle={`Direct Income`} />} />
                <Route path='/cashflow' element={<Cashflow pageTitle={`Cashflow`} />} />
                <Route path='/overview' element={<Overview pageTitle={`Overview`} />} />
                <Route path='/main-overview' element={<MainOverview pageTitle={`Overview`} />} />
                <Route path='/presentations' element={<Presentations pageTitle={`Presentations`} />} />
                <Route path='/ledger' element={<Ledger pageTitle={`Ledger`} />} />
                <Route path='/walletHistory' element={<WalletHistory pageTitle={`Wallet History`} />} />
                <Route path='/update-wallet' element={<UpdateWalletAddress pageTitle={`Wallet History`} />} />
                <Route path='/depositHistory' element={<DepositHistory pageTitle={`Deposit History`} />} />
                <Route path='/transfer-fund' element={<TransFundHistory pageTitle={`Trasfer Fund History`} />} />

                <Route path='/wallet-ledger' element={<EWalletHistory pageTitle={`Wallet Ledger`} />} />



                <Route path='/pay-history' element={<PayHistory pageTitle={`Pay History`} />} />
                <Route path='/create-ticket' element={<Support pageTitle={`Support`} />} />
                <Route path='/ticket-history' element={<TicketView pageTitle={`Ticket View`} />} />
                <Route path='/payout-request' element={<PayoutRequest pageTitle={`Payout Request`} />} />
                <Route path='/all_reports' element={<AllReports pageTitle={`All Reports`} />} />
                <Route path='/pins' element={<PinsReport pageTitle={`Pins Reports`} />} />

                <Route path='/fund' element={<Fund pageTitle={`Fund`} />} />
                <Route path='/withdraw' element={<WIthdraw pageTitle={`Withdraw`} />} />

              </Route>
            </Route>

            {/* All Public pages___ */}
            <Route element={<PrivateLayout />}>
              <Route path='/market' element={<Market pageTitle={`Market`} />} />
            </Route>



          </Routes>
        </LanguageProvider>
      </Router >

      <ToastContainer />
    </>
  );
}

export default App;
