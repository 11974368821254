import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import eng_icon from '../Assets/Icon/english.webp';
import Avtar__img from '../Assets/Icon/user-icon.png';
import { api_url, logo, shortLogo, title } from '../config/Config';
import _fetch from '../config/api';
import toasted from '../config/toast';
import { useLanguage } from "../LanguageContext";
import { Button } from 'react-bootstrap';
import { AccountBalanceWallet, AccountCircle, Close, KeyboardArrowRight, Logout, Wallet, Widgets } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


const Header = () => {

  const { selectedLanguage, changeLanguage } = useLanguage();
  const [selectLangSelect, setselectLangSelect] = useState({
    value: 'en',
    text: <span className="new__a"> English</span>,
    icon: <img src={eng_icon} className="option-img-a" width="20px" />

  });

  const { t, i18n } = useTranslation();


  const changeLanguageInContext = (language) => {
    setselectLangSelect(language)
    changeLanguage(language.value);
    localStorage.setItem('language', language?.value);
  };

  const [open, setOpen] = useState(false);
  const [sponsorId, setSponsorId] = useState('');

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  let location = useLocation();
  const navigate = useNavigate();

  const [theme, setTheme] = useState('dark');

  const modechange = () => {
    if (theme === 'light') {
      setTheme('dark');
    }
    else {
      setTheme('light');
    }
    // setTheme(theme == 'light' ? 'dark' : 'light');

    localStorage.setItem('mode', theme);
  }

  // console.log("theme", theme);


  let modeTheme = localStorage.getItem("mode");


  useEffect(() => {
    const body = document.body;
    body.setAttribute("data-theme", `${modeTheme}`);
  }, [theme]);


  const [userData, setuserData] = useState({ user_info: { email: '', income_earn: 0 } });


  const [isAvatar, setAvatar] = useState("");


  // const cancelTokenSource = axiosInstance.CancelToken.source(); // Create a cancel token source

  useEffect(() => {

    // if (auth === "true") {
    let userDetails = (localStorage.getItem("user"));
    var storeData = JSON.parse(userDetails)
    setuserData(storeData);


    (async () => {
      const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", {});
      console.log('data1?.message', data1?.message);
      if (data1?.message == 'You are not logged in! Please log in to get access.') {
        localStorage.clear();
        navigate("/login");
      }
      if (data1.status === 'success') {
        localStorage.setItem("api_called", 'false');
        setAvatar(data1?.data?.data?.display_profile);

        setSponsorId(data1?.data?.data?.username);
      }
    })();



  }, [location.pathname]);



  const LogOut = async () => {
    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});
    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }

  const copyToClipboard = (address) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success('Address copied successfull!');
  }


  const ToggleButton = () => {
    const absx = document.body;
    absx.classList.toggle("show-index-bar");
  }



  return (
    <>
      <main className='new_main new_main__vih'>
        <div className="app-header">
          <div className='app-header_inner'>
            <div className='for_toggle_scan'>
              <p>
                {location.pathname == '/profile' && <span> {t("Profile")}</span>}
                {location.pathname == '/main-overview' && <span>  {t("Overview")}</span>}
                {location.pathname == '/deposit' && <span> {t("Deposit")}</span>}
                {location.pathname == '/buy-infra' && <span> {t("Activate Account")}</span>}
                {location.pathname == '/trading-profit' && <span> {t("Activation History")}</span>}
                {location.pathname == '/team' && <span> {t("Team")}</span>}
                {location.pathname == '/report/direct_income' && <span> {t("Direct Revenue")}</span>}
                {location.pathname == '/report/level_income' && <span> {t("Level  Bonus")}</span>}
                {location.pathname == '/report/single_leg_income' && <span> {t("Single Leg Revenue")}</span>}
                {location.pathname == '/report/reward_income' && <span> {t("Reward Revenue")}</span>}
                {location.pathname == '/report/loyalty_club_pool1' && <span> {t("Loyalty Club Pool 1")}</span>}
                {location.pathname == '/report/loyalty_club_pool2' && <span> {t("Loyalty Club Pool 2")}</span>}
                {location.pathname == '/report/loyalty_club_pool3' && <span> {t("Loyalty Club Pool 3")}</span>}
                {location.pathname == '/ledger' && <span>{t("Ledger")}</span>}
                {location.pathname == '/Withdraw' && <span>{t("Withdraw")} {t("Request")} </span>}
                {location.pathname == '/pay-history' && <span> {t("Payout")} {t("History")} </span>}
                {location.pathname == '/presentations' && <span> {t("Presentations")}</span>}
                {location.pathname == '/create-ticket' && <span>{t("Create Ticket")}</span>}
                {location.pathname == '/ticket-history' && <span> {t("Ticket History")}</span>}
                {location.pathname == '/all_reports' && <span> {t("Income")}</span>}
                {location.pathname == '/fund' && <span> {t("Fund Transfer")}</span>}
                {location.pathname == '/pins' && <span> {t("Pins")}</span>}
                {location.pathname == '/walletHistory' && <span> {t("Wallet History")}</span>}
                {location.pathname == '/update-wallet' && <span> {t("Update BEP20 Address")}</span>}
                {location.pathname == '/depositHistory' && <span> {t("Wallet TXN History")}</span>}
                {location.pathname == '/transfer-fund' && <span> {t("Transfer Fund History")}</span>}
                {location.pathname == '/wallet-ledger' && <span> {t("All Fund History")}</span>}









              </p>

              {location.pathname == '/dashboard' && <>
                <p> <span> {t("Dashboard")}</span> </p>
                <div class="dashboard__header--sub-title">Fundamental Account Information</div>

              </>}



            </div>
            <div className="right-header-sec">
              <div className='qr__address'>
                <div className="app-sidebar__toggle" data-toggle="sidebar">
                  <Button className="open-toggle btn" type='button' onClick={ToggleButton}>
                    <Widgets />
                    <Close />
                  </Button>
                </div>
                <div className='logo'>
                  <img src={shortLogo} alt='logo' />
                </div>

                {/* <Link className="dashboard__header--account" to={"#"}>
                  <div className="dashboard__header--account__wallet"><AccountBalanceWallet /></div>
                  <div className="dashboard__header--account__amount">{userData?.user_info?.income_earn?.toFixed(2)} <span>USDT</span>
                    <b>
                      <KeyboardArrowRight />
                    </b>
                  </div>
                </Link> */}

                <div className='__drodown__ad'>
                  <Link class="dashboard__header--profile" to="/profile">
                    <img src={isAvatar ? isAvatar : Avtar__img} alt={title + "Logo"} />
                    <span>{(userData?.user_info?.email?.length > 15) && userData?.user_info?.email?.substring(0, 5) + '....' + userData?.user_info?.email?.substring(userData?.user_info?.email?.length - 9, userData?.user_info?.email?.length)}</span>
                  </Link>

                  <div className='__dropdwonData'>
                    <div className='__dropdwoncard'>
                    <Link to="/profile" className='__link'> <AccountCircle/> Edit Profile</Link>
                    <Link to="/update-wallet" className='__link'> <Wallet/> Update Wallet address</Link>
                    <button onClick={LogOut} type='button' className='__link'> <Logout/>  Logout</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>



    </>
  )
}

export default Header