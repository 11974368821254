import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { api_url, titleDescription } from '../../config/Config';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { ColorRing } from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import { Link, useNavigate } from 'react-router-dom';
import { AddTask, HistoryEdu, InfoOutlined, PriceCheck } from '@mui/icons-material';
// import { exit } from 'process';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';

const PayoutRequest = (props: any) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    const [balance, setBalance] = useState(0);
    const [amount, setamount] = useState(0);

    const [deduction, setDeduction] = useState(0);
    const [walletAddresses, setWalletAddresses] = useState({ BEP20: "", TRC20: "" });
    const [activeTab, setActiveTab] = useState(0);
    const [selectedIncomeRadio, setSelectedIncomeRadio] = useState(null);
    const [selectedWalletRadio, setSelectedWalletRadio] = useState('BEP20');
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [otp, setotp] = useState();
    const [btnDisable, setbtnDisable] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        geTotalIncome();
        getUserInfo();

    }, []);

    const geTotalIncome = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}withdraw/check-balance`, "GET", {})
        if (res?.status === 'success') {
            setLoading(false);
            setBalance(res?.balance);
            setDeduction(res?.deduction);
            // setamount(res?.balance);
        }

        let data = await _fetch(`${api_url}withdraw/check-wallet-addresses`, "GET", {})
        if (data?.status === "success") {
            setLoading(false);
            setWalletAddresses(data?.data);
        }
    }

    const handleIncomeRadioChange = (value: any) => {
        setSelectedIncomeRadio(value);
    };

    const handleSecondTabRadioChange = (value: any) => {
        setSelectedWalletRadio(value);
    };

    const handleNext = () => {
        if (balance > 0) {
            console.log('activeTab', activeTab);

            if (activeTab == 1 && SecurityType == 'none') {
                toasted.error("Please activate 2fa ");
                return;
            }
            if (activeTab === 1 && !selectedWalletRadio) {
                toasted.error("Please select atleast one value");
                return;
            }
            setActiveTab((prevTab) => prevTab + 1);
        }
        else {
            toasted.error("Insufficient balance to proceed ")
        }
        // console.log("!walletAddresses", !walletAddresses);

        if (walletAddresses?.BEP20 === null) {
            setOpen(true);
        }
    };


    const withdraw = async () => {
        setbtnDisable(true)
        let data = {
            "amount": amount,
            "type": selectedWalletRadio,
            'otp': otp
        }
        let res = await _fetch(`${api_url}withdraw/withdrawal`, "POST", data, {})
        if (res?.status === 'success') {
            toasted.success(res?.message);
            setActiveTab(0);
            geTotalIncome();
            setbtnDisable(false)

        }
        else {
            setbtnDisable(false)
            toasted.error(res?.message);
        }
    }

    const closeModal = () => {
        setOpen(true);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/profile', { state: { selectedTab: 'Wallet' } });
    };

    const [isButtonClicked, setIsButtonClicked] = useState(false);


    const flipCard = () => {
        setIsButtonClicked(true);
    };

    const [SecurityType, setSecurityType] = useState('none');

    const [userInfo, setUserInfo] = useState({ 'phone': '', 'security_type': '', 'email': '' });
    async function getUserInfo() {
        var d = await _fetch(`${api_url}user/userInfo`, 'GET', {}, {});
        if (d?.status === 'success') {
            setUserInfo(d?.data?.data);

            setSecurityType(d?.data?.data?.security_type);


        }
    }


    return (
        <>
            <main>
                <div className='coustom_container mt-4 mb-4'>

                    <div className="request_main">
                        <div className="request_inner common_card_css loader_main">

                            <Tabs selectedIndex={activeTab} >

                                <TabPanel>
                                    <div className="default-block__title --border-bottom">{t("Choose Withdrawal Funds")}</div>

                                    <div className="panel_section">
                                        <h6 className='block_head'>{t("Choose Your Wallet")}</h6>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='card border-0 income_card mt-3'>
                                                    <div className="checkbox__boxs">
                                                        <label className="checkbox-wrapper" htmlFor='totalIncome'>
                                                            <input type="radio" name="Subject" id="totalIncome" className="checkbox-input" value="option1" onChange={() => handleIncomeRadioChange('option1')} checked />

                                                            <span className="checkbox-tile">
                                                                <div className="card-body">
                                                                    <div className='left-sec'>
                                                                        <div className='icon__leds1'>
                                                                            <MonetizationOnIcon />
                                                                        </div>

                                                                        <div className="total_income">

                                                                            <h6 className='total'>
                                                                                {t("Total Income")}
                                                                            </h6>
                                                                            <h6 className='info'>${balance?.toFixed(4)}</h6>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="button_sec">
                                            <a className="new_button_css_dashboard" onClick={handleNext} >{t("Next")}<ArrowForwardIosIcon /></a>
                                        </div>
                                    </div>


                                </TabPanel>

                                <TabPanel>
                                    <div className="number">
                                        <span className='number_style_1'> 2 </span>
                                    </div>

                                    <div className="text_head">  <span className='number_style_1'> <PriceCheck /> </span> {t("Choose Your Wallet")}</div>

                                    <div className="panel_section">
                                        <h6 className='block_head'>{t("Choose Your Wallet")}</h6>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='card income_card'>
                                                    <div className="checkbox__boxs">
                                                        <label className="checkbox-wrapper" htmlFor='BEP20_address'>
                                                            <input type="radio" className="checkbox-input" id="BEP20_address" name="Subject" value="BEP20" onChange={() => handleSecondTabRadioChange('BEP20')} checked />

                                                            <span className="checkbox-tile">
                                                                <div className="card-body">
                                                                    <div className='left-sec'>
                                                                        <div className='icon__leds1'>
                                                                            <AccountBalanceWalletIcon />
                                                                        </div>

                                                                        <div className="total_income">
                                                                            <h6 className='total'> {t("Wallet Type")} </h6>
                                                                            <h6 className='info'> BEP20: {walletAddresses?.BEP20}</h6>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className='col-lg-12'>
                    <div className='card income_card'>
                        <div className="checkbox__boxs">
                            <label className="checkbox-wrapper" htmlFor='TRC20_address'>
                                <input type="radio" className="checkbox-input" id="TRC20_address" name="Subject" value="TRC20" onChange={() => handleSecondTabRadioChange('TRC20')}  />

                                <span className="checkbox-tile">
                                    <div className="card-body">
                                        <div className='left-sec'>
                                            <div className='icon__leds1'>
                                                <AccountBalanceWalletIcon />
                                            </div>

                                            <div className="total_income">
                                                <h6 className='total'> {t("Wallet Type")} </h6>
                                                <h6 className='info'> BEP20: {walletAddresses?.TRC20}</h6>
                                            </div>
                                        </div>

                                    </div>
                                </span>
                            </label>
                        </div>
                    </div>
                </div> */}
                                        </div>

                                        <div className="button_sec">
                                            {/* <a className="btn" onClick={handleNext}   >{t("Next")}< ArrowForwardIosIcon /></a> */}
                                            <a className="btn" onClick={() => { flipCard(); handleNext() }}  >{t("Next")}< ArrowForwardIosIcon /></a>
                                        </div>
                                    </div>

                                </TabPanel>

                                <TabPanel>

                                    <div className="number">
                                        <span className='number_style_1'> 3 </span>
                                    </div>

                                    <div className="text_head">  <span className='number_style_1'> <AddTask /> </span> {t("Confirm Payout Information")}</div>

                                    {/* <p className='mb-0 text-info'> <InfoOutlined /> {t("It may take 24hours for withdraw approval")}!  </p> */}


                                    <div className="panel_section">
                                        <h6 className='block_head'>{t("Choose Withdrawal Funds")}</h6>

                                        <div className="panel_inner">
                                            <p>{t("Balance")}: <span className='amount'>${balance}</span></p>
                                            <div className="pay-req">
                                                <h6>{t("Payout Request")}:</h6>
                                                {/* <h6 className='amount'>${balance > 0 ? balance?.toFixed(2) : "0"}</h6> */}
                                                <input type='number' className='payout-request' onChange={(e: any) => { e.target.value >= 0 ? setamount(e.target.value) : toasted.error('Please enter a valid amount!'); }} value={amount} />
                                                {/* / /// onChange={(e: any) => setamount(e.target.value)} value={amount} /> */}
                                            </div>
                                            <div className='pay-req_charges'>
                                                <div className="pay-req">
                                                    <h6>{t("Admin Handling Charges")}:</h6>
                                                    <h6 className='amount'>${((amount / 100) * (100 * deduction))?.toFixed(4)}</h6>
                                                </div>

                                                <div className="pay-req">
                                                    <h6>{t("Release Payout")}:</h6>
                                                    <h6 className='amount'>${(amount / 100 * (100 - (100 * deduction)))?.toFixed(4)}</h6>
                                                </div>
                                            </div>

                                            <div className="pay-req">
                                                <h6>{t("Auth Code")}:</h6>
                                                {/* <h6 className='amount'>${balance > 0 ? balance?.toFixed(2) : "0"}</h6> */}
                                                <input type='number' className='payout-request' onChange={(e: any) => { setotp(e.target.value) }} value={otp} />
                                                {/* / /// onChange={(e: any) => setamount(e.target.value)} value={amount} /> */}
                                            </div>
                                        </div>

                                        <div className="button_sec">
                                            <button type='button' className="btn button_style " onClick={withdraw} disabled={btnDisable}>
                                                {btnDisable === true &&
                                                    <Loader LoaderTxt={'Please Wait...'} />
                                                }
                                                {btnDisable === false && <>{t("Confirmation")} < ArrowForwardIosIcon /></>}

                                            </button>
                                        </div>
                                    </div>


                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>


                {open && (

                    <Modal open={open} onClose={closeModal} center
                        classNames={{
                            modal: "request_modal",
                        }}>
                        <div className="request_inner">
                            <p>Please update your wallet addresses</p>
                            {/* <Link to={{ pathname: "/profile", state: {selectedTab: 'Wallet'}} as any} className='button_style text-deco-none'>Click Here</Link> */}
                            <button onClick={handleClick} className='new_button_css_dashboard'>
                                Click Here
                            </button>

                        </div>
                    </Modal>
                )}
            </main>

        </>

    )

}

export default PayoutRequest