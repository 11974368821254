import React, { useEffect, useState } from "react";
import { api_url, incomesList, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';
import { Description } from "@mui/icons-material";
import NoResult from "./NoResult";

const WalletHistory = (props: any) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [allIncomes, setAllIncomes] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [incomeChange, setIncomeChange] = useState('wallet_address');

  useEffect(() => {
    getAllIncomeReport();
  }, [ page, totalRecord]);


  const getAllIncomeReport = async () => {
    setLoading(true);
    
      let res = await _fetch(`${api_url}report/walletActivities?limit=10&page=${page}`, "GET", {})

      if (res?.status === "success") {
        setLoading(false);
        setAllIncomes(res?.data?.data);
        setTotalRecord(res?.total);
      }
    
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };
  return (
    <main>
      <div className="coustom_container">

        <div className="uni-level-outer">
          <div className="level_inner common_card_css loader_main">
            <div className='sub-header-kt sub-header-flex'>
              <div className="led-selected">
                {/* <form className="ledger-form">
                  <label className="form_lable"> Select Type</label>
                  <select className="form-control ledger" onChange={(e: any) => { setIncomeChange(e.target.value); setpage(1) }}>
                   
                    <option value={'wallet_address'}>{t('Wallet Address')}</option>

                         
                  </select>
                </form> */}
              </div>
            </div>
            <div className="table_section mt-4">
              <div className="scrolling_table">
                <div className="scroll_under">
                  <div className="table_responsive">
                    <Table className="support_table all_tables">
                      <thead className='thead_dashboard'>
                        <tr className="table_heading_row">
                        <th>{t("#")}</th>

                          <th>{t("Date")}</th>
                          <th>{t("Wallet Address")}</th>
                         
                          
                        </tr>
                      </thead>
                      <tbody className='tbody_dashboard'>
                        {allIncomes?.length == 0 ? <tr className='no_data_tr'>
                          <td colSpan={6}>
                            <NoResult />
                          </td>
                        </tr> :

                          <>
                            {allIncomes?.map((item: any, index: any) => {
                              return (
                                <>

                                  <tr className="table_data_row" key={index}>
                                    <td className="table_data"> {index+1}</td>
                                    
                                    <td className="table_data">
                                      <div className="pic_text">{dateFormat(item?.updated_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</div>
                                    </td>
                                    <td className="table_data"> {item?.wallet_address}</td>
                                    
                                  </tr>
                                </>
                              )
                            })}
                          </>
                        }

                      </tbody>
                    </Table>
                    {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      pageCount={totalRecord / 10}
                      previousLabel="<"
                    />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default WalletHistory;
