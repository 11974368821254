import React, { useEffect, useState } from 'react';
import { AddBusiness, DateRange, Diversity1, Diversity2, EditCalendar, Person, Today } from '@mui/icons-material';
import { Col, Row, Table } from 'react-bootstrap';
import _fetch from '../../config/api';
import { api_url, title } from '../../config/Config';
import { useTranslation } from 'react-i18next';

const MainOverview = (props: any) => {
    const { t } = useTranslation();

    const [allUserData, setAllUserData] = useState({ totalTeam: 0, boosterIncome: 0, totalIncome: "", avaliableIncome: "", TotalDeposit: "", AvaliableDeposit: { fundValue: "", reBuyValue: "" }, directIncome: "", levelIncome: "", poolIncome: "", roiIncome: "", royaltyIncome: "", profitDetails: { todayProfit: 0, yesterdayProfit: 0, lastMonthProfit: 0, currentMonthProfit: 0, last7DaysProfit: 0 }, userInfo: { user_info: { name: "", income_limit: "", package: { current_package: "", previous_package: "", total_package: "" } } } });

    const getUserData = async () => {
        // setLoading(true);
        let res = await _fetch(`${api_url}profile/dashboard`, "GET", {})

        if (res?.status === "success") {

            setAllUserData(res?.data);
            console.log('res?.data', res?.data);


        }
    }


    const [info, setInfo] = useState({ business: { direct_business: 0, team_business: 0 }, directs: { total: 0 } });

    const getUserInfo = async () => {
        let res = await _fetch(`${api_url}user/userInfo/`, "GET", {})
        if (res?.status === 'success') {
            setInfo(res?.data?.data);
        }
    }
    useEffect(() => {
        document.title = `${props.pageTitle} | ${title} `;

        getUserData();
        getUserInfo();
    }, []);

    return (
        <>
            <main>
                <div className='coustom_container'>
                    <div className='main-overview'>
                        <Row>
                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Team")} Total</h5>
                                            <h4 className='m-0'>{allUserData?.totalTeam ? allUserData?.totalTeam : '0'}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><Diversity2 /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Frontlines")}</h5>
                                            <h4 className='m-0'>{info?.directs?.total}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><Diversity1 /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Direct Business")}</h5>
                                            <h4 className='m-0'>${info?.business?.direct_business ? info?.business?.direct_business : 0}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><AddBusiness /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Total Business")}</h5>
                                            <h4 className='m-0'>${info?.business?.team_business ? info?.business?.team_business : 0}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><AddBusiness /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Yesterday")}</h5>
                                            <h4 className='m-0'>${allUserData?.profitDetails?.yesterdayProfit ? allUserData?.profitDetails?.yesterdayProfit : "0"}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><Today /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Last 7 Days")}</h5>
                                            <h4 className='m-0'>${allUserData?.profitDetails?.last7DaysProfit ? allUserData?.profitDetails?.last7DaysProfit : '0'}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><DateRange /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Current Month")}</h5>
                                            <h4 className='m-0'>${allUserData?.profitDetails?.currentMonthProfit ? allUserData?.profitDetails?.currentMonthProfit : '0'}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><EditCalendar /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col lg={4} sm={6}>
                                <div className='__v1_card card '>
                                    <div className='card-body'>
                                        <div className='__left_v1'>
                                            <h5>{t("Last Month")}</h5>
                                            <h4 className='m-0'>${allUserData?.profitDetails?.lastMonthProfit ? allUserData?.profitDetails?.lastMonthProfit : '0'}</h4>

                                        </div>

                                        <div className='right_v1'>
                                            <p><EditCalendar /></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </div>
            </main>
        </>
    );
}

export default MainOverview;
