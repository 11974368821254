import React, { useEffect, useState } from 'react'
import { api_url, currency, titleDescription } from '../../config/Config';
import { KeyboardArrowRight, Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _fetch from '../../config/api';
import { Link } from 'react-router-dom';

const AllReports = (props: any) => {

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
        getUserData()
    },[]);

    const { t, i18n } = useTranslation();
    const [allUserData, setAllUserData] = useState({ boosterIncome: 0, totalIncome: "0", avaliableIncome: "0", TotalDeposit: "0", AvaliableDeposit: { fundValue: "", reBuyValue: "" }, directIncome: "0", levelIncome: "0", poolIncome: "0", roiIncome: "0", royaltyIncome: "0", userInfo: { user_info: { name: "", income_limit: "", package: { current_package: "", previous_package: "", total_package: "" } } } });
    const getUserData = async () => {
        // setLoading(true);
        let res = await _fetch(`${api_url}profile/dashboard`, "GET", {})
    
        if (res?.status === "success") {
    
          setAllUserData(res?.data);
          
    
        }
      }
    return (
        <div>
            <main>
                <div className="all_reposrts_sec">
                    <div className="coustom_container">
                        <div className="dashboard__wrapper">
                            <div className="accounts-list">
                                <Link className="accounts-list__element " to="/report/direct_income">
                                    <div className="accounts-list__element--name">
                                        <Check />
                                        {t("Direct Profit")}</div>
                                    <div className="accounts-list__element--info">
                                        <div className="accounts-list__element--balance">{allUserData?.directIncome >= "0" ? <> {Number(allUserData?.directIncome)?.toFixed(2)} </> : "0.00"}
                                            <span>{currency} </span>
                                        </div>
                                        <span className="accounts-list__element--arrow">
                                            <KeyboardArrowRight />
                                        </span>
                                    </div>
                                </Link>
                                <Link className="accounts-list__element " to="/pay-history">
                                    <div className="accounts-list__element--name">
                                        <Check />
                                        {t("Withdrawable Balance")} </div>
                                    <div className="accounts-list__element--info">
                                        <div className="accounts-list__element--balance">{allUserData?.avaliableIncome >= "0" ? <> {Number(allUserData?.avaliableIncome)?.toFixed(2)} </> : '0.00'}
                                            <span>{currency} </span>
                                        </div>
                                        <span className="accounts-list__element--arrow">
                                            <KeyboardArrowRight />
                                        </span>
                                    </div>
                                </Link>
                                <Link className="accounts-list__element " to="/report/level_income">
                                    <div className="accounts-list__element--name">
                                        <Check />
                                        {t("Layered Investment Returns")}</div>
                                    <div className="accounts-list__element--info">
                                        <div className="accounts-list__element--balance">{allUserData?.levelIncome >= "0" ? <> {Number(allUserData?.levelIncome)?.toFixed(2)}</> : "0.00"}
                                            <span>{currency} </span>
                                        </div>
                                        <span className="accounts-list__element--arrow">
                                            <KeyboardArrowRight />
                                        </span>
                                    </div>
                                </Link>
                                <Link className="accounts-list__element " to="/report/roi_income">
                                    <div className="accounts-list__element--name">
                                        <Check />
                                        {t("Profit on Investment")}</div>
                                    <div className="accounts-list__element--info">
                                        <div className="accounts-list__element--balance">{(allUserData?.roiIncome >= "0") ? <> {Number(allUserData?.roiIncome)?.toFixed(2)} </> : "0.00"}
                                            <span>{currency} </span>
                                        </div>
                                        <span className="accounts-list__element--arrow">
                                            <KeyboardArrowRight />
                                        </span>
                                    </div>
                                </Link>
                                <Link className="accounts-list__element " to="/report/royalty_income">
                                    <div className="accounts-list__element--name">
                                        <Check />
                                        {t("Royalty Profit")}</div>
                                    <div className="accounts-list__element--info">
                                        <div className="accounts-list__element--balance">{allUserData?.royaltyIncome >= "0" ? <> {Number(allUserData?.royaltyIncome)?.toFixed(2)}</> : "0.00"}
                                            <span>{currency} </span>
                                        </div>
                                        <span className="accounts-list__element--arrow">
                                            <KeyboardArrowRight />
                                        </span>
                                    </div>
                                </Link>
                                <Link className="accounts-list__element " to="/report/global_lifetime_pool_income">
                                    <div className="accounts-list__element--name">
                                        <Check />
                                        {t("Eternal Global Pool Profit")}</div>
                                    <div className="accounts-list__element--info">
                                        <div className="accounts-list__element--balance">{allUserData?.poolIncome >= "0" ? <> {Number(allUserData?.poolIncome)?.toFixed(2)}</> : "0.00"}
                                            <span>{currency} </span>
                                        </div>
                                        <span className="accounts-list__element--arrow">
                                            <KeyboardArrowRight />
                                        </span>
                                    </div>
                                </Link>
                                <Link className="accounts-list__element " to="/report/booster_income">
                                    <div className="accounts-list__element--name">
                                        <Check />
                                        {t("Booster Profit")}</div>
                                    <div className="accounts-list__element--info">
                                        <div className="accounts-list__element--balance">{allUserData?.boosterIncome >= 0 ? <> {Number(allUserData?.boosterIncome)?.toFixed(2)}</> : "0.00"}
                                            <span>{currency} </span>
                                        </div>
                                        <span className="accounts-list__element--arrow">
                                            <KeyboardArrowRight />
                                        </span>
                                    </div>
                                </Link>
                            </div>
                            <div className="accounts-list__footer">
                                <div className="accounts-list__total">
                                    <p>Total assets:</p>
                                    <div className="accounts-list__element--balance">
                                    {allUserData?.totalIncome >= "0" ? `${Number(allUserData?.totalIncome).toFixed(2)}` : "0"}
                                        <span>USDT</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AllReports
