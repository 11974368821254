import React, { useEffect, useState } from 'react'
import { api_url, titleDescription, website } from '../../config/Config';
import { Table } from 'react-bootstrap';
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from '../../config/api';
import dateformat from "dateformat";
import { useLocation, useParams } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import { Description } from '@mui/icons-material';
import NoResult from './NoResult';
import toasted from '../../config/toast';
import CopyAllIcon from '@mui/icons-material/CopyAll';
const PinsReport = (props: any) => {
    const { t, i18n } = useTranslation();

    const { type } = useParams();
    let location = useLocation();



    const [PinsData, setPinsData] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [levelSearch, setLevelSearch] = useState('');
    const [searchedType, setSearchType] = useState('level');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [allLevels, setAllLevels] = useState([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
    ]);
    const [keyCount, setKeycount] = useState(0);

    useEffect(() => {
        getIncomeReport();

    }, [levelSearch, page, type]);

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
        setStartDate('');
        setEndDate('')
    }, [type]);

    const getIncomeReport = async () => {
        setLoading(true);


        let res = await _fetch(`${api_url}report/pins`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setPinsData(res?.data?.data);

        }

    }

    const renewPin = async (pin:any) => {


        let res = await _fetch(`${api_url}fund/renewPin`, "POST",{'pin': pin}, {})
        if (res?.status === "success") {
            getIncomeReport();
            toasted.success(res?.message);
        
        }else{
            toasted.error(res?.message);

        }

    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };

    const refresh = () => window.location.reload();
    const copyToClipboard = (address: any, err: any) => {

        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toasted.success(err);
    }
    return (
        <main>

            <div className='coustom_container'>
                <div className="uni-level-outer">
                    <div className="level_inner common_card_css loader_main">
                        {loading &&
                            <div className="spinner_bg">

                                <div className="color_ring">  <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"

                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                /></div>

                            </div>
                        }

                        <div className="table_section mt-4">
                            <div className="scrolling_table">
                                <div className="scroll_under">
                                    <div className="table_responsive">
                                        <Table className="support_table all_tables">
                                            <thead className='thead_dashboard'>
                                                <tr className="table_heading_row">
                                                    <th>{t("#")}</th>

                                                    <th>{t("Pin")}</th>

                                                    <th>{t("Used For")}</th>
                                                    <th>{t("Status")}</th>
                                                    <th>{t("Expire Status ")}</th>
                                                    <th>{t("Created at ")}</th>

                                                    <th>{t("Expire at ")}</th>

                                                    <th>{t("Action")}</th>


                                                </tr>
                                            </thead>
                                            <tbody className='tbody_dashboard'>
                                                {PinsData.length == 0 ? <tr className='no_data_tr'>
                                                    <td colSpan={9}>
                                                        <NoResult />
                                                    </td>
                                                </tr> :

                                                    <>
                                                        {PinsData?.map((item: any, index: any) => {
                                                            return (
                                                                <>

                                                                    <tr className="table_data_row">
                                                                        <td className="table_data">{index + 1}</td>

                                                                        <td className="table_data">{item?.pin}
                                                                        <button className="btn btn-sm  m-1 " onClick={() => { copyToClipboard(item?.pin,'Pin  Copied!') }}><CopyAllIcon /></button>

                                                                        </td>
                                                                        <td className="table_data">{item?.used_for ? item?.used_for : '-'}</td>
                                                                        <td>{item?.status == 0 ? <span className="text-success">Available</span> : <span className="text-info">Used</span>}</td>

                                                                        <td>{item?.expire_status == 0 ? item?.status == 1 ? <span className="text-info">Used</span> : <span className="text-success">Available</span> : <button className="btn btn-sm btn-info  text-white" onClick={() => {renewPin(item?.pin)}}>Renew</button>}</td>

                                                                        <td className="table_data payment_fee">
                                                                            {item?.status == 0  && dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                                                                        </td>
                                                                        <td className="table_data payment_fee">
                                                                            {dateformat(item?.expire_date, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                                                                        </td>


                                                                        {item?.expire_status == 0 &&  item?.status == 0 ? <td className="table_data payment_fee">
                                                                            <a className="new_button_css_dashboard" href={`${website}register/${item?.username}/${item?.pin}`} >Register Now</a>
                                                                            <button className="btn btn-sm  m-1" onClick={() => { copyToClipboard(`${website}register/${item?.username}/${item?.pin}`,'Register Link Copied!') }}><CopyAllIcon /></button>

                                                                        </td> : <td className="table_data payment_fee"></td>}

                                                                        
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}</>
                                                }
                                            </tbody>
                                        </Table>
                                        {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={1}
                                            pageCount={totalRecord / 10}
                                            previousLabel="<"
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PinsReport