import React, { useEffect } from 'react'
import { titleDescription } from '../../config/Config';
import { logo } from '../../config/Config';
import england from '../../Assets/Image/england.webp';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';



const Presentations = (props: any) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    return (
        <main>
            <div className='coustom_container'>
                <div className="presentation_main ">
                    <div className="present_inner">
                        <div className="row present_row">
                            <div className="col-xl-6 col-md-6">
                                <div className="card present_card">
                                    <div className="card-body">
                                        <div className="image_logo">
                                            <img src={logo} className="header-brand-img light-logo" alt="logo" />

                                            <p> {t("Presentations")}</p>
                                        </div>

                                        <div className="country_sec">
                                            <img src={england} className='logo_sec' alt="logo" />
                                            <h6>England</h6>
                                        </div>

                                        <a href="#" className='new_button_css_dashboard me-3' download> <DownloadIcon /> Download</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6">
                                <div className="card present_card">
                                    <div className="card-body">
                                        <div className="image_logo">
                                            <img src={logo} className="header-brand-img light-logo" alt="logo" />

                                            <p> {t("Presentations")}</p>
                                        </div>

                                        <div className="country_sec">
                                            <img src={england} className='logo_sec' alt="logo" />
                                            <h6>England</h6>
                                        </div>

                                        <a href="#" className='new_button_css_dashboard me-3' download> <DownloadIcon /> Download</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Presentations